import { Checkbox, Table, Typography } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import PaginationGeneric from './PaginationGeneric';
import { IAssignmentRuleItem } from '../hooks/useAssignmentRules';
import TagList from './TagList';
const { Text } = Typography;
const { Column } = Table;

export default (props: {
  data: IAssignmentRuleItem[];
  total: number;
  loading: boolean;
  onPageChange?: (val: number, pageSize: number, sort: string) => void;
  onSortChange?: (sort: string) => void;
  renderActions: (IAssignmentRuleItem: IAssignmentRuleItem) => JSX.Element;
}) => {
  const { onPageChange, data, loading, total, renderActions } = props;
  const [sort, setSort] = useState('value asc');
  const [resetPagination, setResetPagination] = useState(false);
  const pageChange = (val: number, pageSize: number, sort: string) => {
    if (typeof onPageChange === 'function') onPageChange(val, pageSize, sort);
  };

  const handleChange = (_val: any, sorter: any) => {
    let sortVal = sorter?.order === 'descend' ? 'desc' : 'asc';
    const field = sorter.field; // value, status
    // Inverting logic for Status (true/false)
    if (field === 'active') sortVal = sortVal === 'desc' ? 'asc' : 'desc';

    setSort(`${field} ${sortVal}`);
    setResetPagination(true);
    setTimeout(() => setResetPagination(false), 500);
  };

  return (
    <>
      <Table
        rowKey='value'
        dataSource={data}
        pagination={false}
        loading={loading}
        style={{ overflowY: 'auto' }}
        sortDirections={['ascend', 'descend', 'ascend']}
        onChange={(val: any, _filter: any, sorter: any, _extra: any) => {
          handleChange(val, sorter);
        }}
      >
        <Column
          title='Name'
          key='assignment-rule-name'
          defaultSortOrder='ascend'
          sorter={true}
          showSorterTooltip={false}
          render={(obj) =>
            obj?.id && (
              <Link to={`${obj.id}`}>
                <span>{obj.name || obj.code}</span>
              </Link>
            )
          }
        />{' '}
        <Column
          title='type'
          key='assignment-rule-type'
          defaultSortOrder='ascend'
          align='center'
          showSorterTooltip={false}
          render={(obj) => <Text code>{obj.type === 1 ? 'Okta Group Membership Rule' : '?'}</Text>}
        />{' '}
        <Column
          title='Assignment'
          key='assignment-rule-assignment'
          defaultSortOrder='ascend'
          align='left'
          showSorterTooltip={false}
          render={(obj) => obj?.code && <TagList tags={[obj.code]} />}
        />{' '}
        <Column
          title='lenient'
          key='assignment-rule-lenient'
          defaultSortOrder='ascend'
          width='100px'
          align='center'
          showSorterTooltip={false}
          render={(obj) => <Checkbox checked={obj.lenient} disabled={true} />}
        />{' '}
        <Column
          width='100px'
          title='Actions'
          key='assignment-rule-table-col-actions'
          align='center'
          render={renderActions}
        />
      </Table>

      <PaginationGeneric
        total={total}
        hideOnSinglePage={true}
        showSizeChanger={true}
        resetPagination={resetPagination}
        onPageChange={(page, pageSize) => {
          pageChange(page, pageSize, sort);
        }}
      ></PaginationGeneric>
    </>
  );
};
