import { Table } from 'antd';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PaginationGeneric from './PaginationGeneric';
import useLocalStorage from 'hooks/useLocalStorage';

const { Column } = Table;

export default (props: { syncList: any; loading: boolean; onPageChange?: (val: number, pageSize: number) => void }) => {
  const { syncList, loading } = props;

  const [lsPageSize] = useLocalStorage<number>('am-page-size', 10);
  const [syncsArr, setSyncsArr] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeInner, setPageSizeInner] = useState(lsPageSize);

  useEffect(() => {
    setSyncsArr(syncList);
  }, [syncList]);

  const pageChange = (currentPage: number, pageSize: number) => {
    setCurrentPage(currentPage);
    setPageSizeInner(pageSize);
  };

  // Amount of data to pass to Table
  const calPage = (currentPage - 1) * pageSizeInner;
  let syncsToShow = syncsArr?.slice(calPage, calPage + pageSizeInner);

  useEffect(() => {
    if (syncsArr && syncsArr.length > pageSizeInner) {
      syncsToShow = syncsArr?.slice(calPage, calPage + pageSizeInner);
      if (!syncsToShow?.length) {
        pageChange(1, pageSizeInner);
      }
    } else {
      syncsToShow = syncsArr;
      if (syncsToShow?.length) setCurrentPage(1);
    }
  }, [syncsToShow]);

  const formatDate = (dateStr: string) => {
    const dateObj = new Date(dateStr);
    const year = dateObj.getUTCFullYear();
    const month = dateObj.getUTCMonth() + 1;
    const date = dateObj.getUTCDate();

    const fMonth = month < 10 ? `0${month}` : month;
    const fDate = date < 10 ? `0${date}` : date;

    const hours = dateObj.getUTCHours();
    const minutes = dateObj.getUTCMinutes();
    const seconds = dateObj.getUTCSeconds();

    const fHours = hours < 10 ? `0${hours}` : hours;
    const fMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const fSeconds = seconds < 10 ? `0${seconds}` : seconds;

    return `${year}-${fMonth}-${fDate} ${fHours}:${fMinutes}:${fSeconds}`;
  };

  return (
    <>
      <Table rowKey='_id' dataSource={syncsToShow} pagination={false}>
        <Column title='ID' key='_id' render={(sync: any) => <Link to={`../${sync._id}`}>{sync._id}</Link>} />
        <Column
          title='File Name'
          key='fileName'
          render={(sync: any) => <Link to={`../${sync._id}`}>{sync.fileName}</Link>}
        />
        <Column
          title='Cancelled'
          key='isCancelled'
          align='center'
          render={(sync: any) => <Link to={`../${sync._id}`}>{sync.isCancelled.toString()}</Link>}
        />
        <Column
          title='Date'
          key='created'
          align='center'
          render={(sync: any) => <Link to={`../${sync._id}`}>{formatDate(sync.created)}</Link>}
        />
      </Table>

      <PaginationGeneric
        loading={loading}
        hideOnSinglePage={true}
        showSizeChanger={true}
        onPageChange={(page, pageSize) => {
          pageChange(page, pageSize);
        }}
      ></PaginationGeneric>
    </>
  );
};
