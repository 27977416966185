import { Button, Checkbox, Col, Form, Input, Modal, Typography, Row, Spin, notification, Space } from 'antd';
import { useNavigate, Link, useLocation, useParams } from 'react-router-dom';
import { Page, ResourceSelect, RolesSelect, OktaGroupSelect } from '../components';
import { useEffect, useState } from 'react';
import useAssignmentRules, { IAssignmentRuleItem } from '../hooks/useAssignmentRules';
import { ThunderboltOutlined } from '@ant-design/icons';
import generalContent from '../conf/generalContent.json';
import TextArea from 'antd/es/input/TextArea';
import usePermissions from '../hooks/usePermissions';
import { IRole } from '../hooks/useRoles';
import { IResource } from '../hooks/useResources';
import { NotFoundPage } from './index';
import usePrevious from '../hooks/usePrevious';
import useAssignments from '../hooks/useAssignments';

const { Text, Paragraph } = Typography;

export default () => {
  const { applicationId = '', assignmentRuleId = '' } = useParams<{
    applicationId: string;
    assignmentRuleId: string;
  }>();
  const { data, error, loading, getAssignmentRule, createAssignmentRule, updateAssignmentRule } = useAssignmentRules();
  const { pathname } = useLocation();
  const { checkPermissions } = usePermissions();
  const [assignmentRuleSelected, setAssignmentRuleSelected] = useState<IAssignmentRuleItem>({
    name: '',
    description: '',
    dynamic: true,
    type: 1,
    applicationId,
    id: '',
    lenient: false,
    code: '',
    oktaGroupId: '',
    resourceId: '',
    roleId: '',
  });
  const [currentRole, setCurrentRole] = useState<IRole | null>();
  const [currentResource, setCurrentResource] = useState<IResource | null>();
  const [assignmentCode, setAssignmentCode] = useState<string>('');
  const { code: applicationCode } = JSON.parse(localStorage.getItem('application') || '{}');
  const [visibleSaveAssignmentRuleModal, setVisibleSaveAssignmentRuleModal] = useState<boolean>(false);
  const [saveAssignmentRuleModalLoading, setSaveAssignmentRuleModalLoading] = useState<boolean>(false);
  const { roles, getAllApplicationAssignments, assignmentMap } = useAssignments();
  const [allowedRoleIds, setAllowedRoleIds] = useState<string[]>([]);
  const [fromAppId, setFromAppId] = useState(false);
  const edit = pathname.endsWith('/edit');
  const create = pathname.endsWith('/create');
  const readOnly = !edit && !create;
  const disabled = readOnly || (!create && loading);
  const [formInstance] = Form.useForm();
  const navigate = useNavigate();
  const previousApp = usePrevious(applicationId);
  const [lenient, setLenient] = useState<boolean>(false);

  const loadAssignmentRule = () => {
    getAllApplicationAssignments(applicationId);
    getAssignmentRule(applicationId, assignmentRuleId);
  };

  useEffect(() => {
    return () => {
      formInstance.setFieldsValue(assignmentRuleSelected);
    };
  }, [navigate]);

  useEffect(() => {
    if (edit && assignmentRuleId) loadAssignmentRule();
  }, [edit]);

  useEffect(() => {
    // Redirect to Dynamic Assignments page the user are in the edit page and change the application
    if (previousApp) navigate(`/${applicationId}/dynamic-assignments`);

    // Request Assignment Rule ID by id
    if (applicationId && assignmentRuleId) getAssignmentRule(applicationId, assignmentRuleId);
  }, [applicationId]);

  const reloadAssignmentCode = () => {
    let assignmentParts = [applicationCode];
    if (currentResource) {
      if (currentResource?.parentResource?.code) {
        assignmentParts.push(currentResource?.parentResource?.code);
      }
      assignmentParts.push(currentResource?.code);
    }
    if (currentRole?.code) {
      assignmentParts.push(currentRole?.code);
    } else {
      assignmentParts = [];
    }
    const assignmentCode = assignmentParts.join('.');
    setAssignmentCode(assignmentCode);
    formInstance.setFieldValue('code', assignmentCode);
    const name = formInstance.getFieldValue('name');

    if (assignmentCode && name === '') {
      formInstance.setFieldValue('name', assignmentCode);
      formInstance.validateFields();
    }
  };

  useEffect(() => {
    getAllApplicationAssignments(applicationId);
  }, [create]);

  useEffect(() => {
    reloadAssignmentCode();
    setAllowedRoleIds(currentResource?.allowedRoleIds || []);
  }, [currentRole, currentResource]);

  useEffect(() => {
    setAssignmentCode(assignmentCode);
  }, [assignmentCode]);

  useEffect(() => {
    assignmentRuleId && getAssignmentRule(applicationId, assignmentRuleId);
  }, [assignmentRuleId]);

  useEffect(() => {
    if (data) {
      setAssignmentRuleSelected(data);
    }
  }, [data]);

  useEffect(() => {
    if (assignmentRuleSelected?.roleId) {
      formInstance.setFieldsValue(assignmentRuleSelected);
    }
  }, [assignmentRuleSelected]);

  const onResourceChange = (resource: IResource | null) => {
    setCurrentResource(resource);
    formInstance.setFieldValue('roleId', '');
  };

  const onRoleChange = (role: IRole | null) => {
    setCurrentRole(role);
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const colStyle = {
    xs: 24,
    lg: 12,
    xl: 10,
    xxl: 8,
  };
  const fieldClassName = readOnly ? 'show-only-field' : '';

  useEffect(() => {
    if (error?.data) {
      const key = `error-notification`;
      const btn = error.data.assignments && (
        <Button danger onClick={() => notification.close(key)}>
          Close
        </Button>
      );
      notification.error({
        message: 'Attention',
        key,
        description: (
          <div>
            <Text>{`${error?.data?.error || 'Error occur during the operation'}`}</Text>
            <br />
            <br />
            {error?.data?.assignments && <Text strong>Assignments:</Text>}
            {error?.data?.assignments && (
              <Paragraph>
                <ul>
                  {((error?.data?.assignments as any) || []).map((assignment: string) => (
                    <li key={`li-item-${assignment}`}>
                      <Typography.Link onClick={() => navigate(`/${applicationId}/assignments/${assignment}/users`)}>
                        {assignment}
                      </Typography.Link>
                    </li>
                  ))}{' '}
                </ul>
              </Paragraph>
            )}
            <Space></Space>
          </div>
        ),
        btn,
        duration: !error.data.assignments ? null : 5,
      });
    }
  }, [error]);

  useEffect(() => {
    create && formInstance.resetFields(['roleId']);
  }, [lenient]);

  function setRole() {
    reloadAssignmentCode();
  }

  function setResource() {
    reloadAssignmentCode();
  }

  const onFinish = function (values: IAssignmentRuleItem) {
    setAssignmentRuleSelected(values);
    setVisibleSaveAssignmentRuleModal(true);
  };

  const handleOk = function () {
    // Gets fields values.Empty fields can be undefined.
    const assignmentRuleSelected = formInstance.getFieldsValue();
    assignmentRuleSelected.description ??= '';
    assignmentRuleSelected.type ??= 1;

    setAssignmentRuleSelected(assignmentRuleSelected);

    if (!create && fromAppId) {
      setFromAppId(false);
      history.back();
    }

    setSaveAssignmentRuleModalLoading(true);

    const success = (description: string) => {
      setVisibleSaveAssignmentRuleModal(false);
      setSaveAssignmentRuleModalLoading(false);
      notification.success({
        message: 'Success',
        description,
      });
    };

    if (create) {
      createAssignmentRule(applicationId, assignmentRuleSelected, (data) => {
        setSaveAssignmentRuleModalLoading(false);
        if (!(data instanceof Error)) {
          success(`Assignment rule was successfully created!`);
          navigate(`/${applicationId}/dynamic-assignments/${data.id}`);
        }
      });
    } else {
      updateAssignmentRule(applicationId, assignmentRuleId, assignmentRuleSelected, (data) => {
        loadAssignmentRule();
        setSaveAssignmentRuleModalLoading(false);
        if (!(data instanceof Error)) success(`Assignment rule was successfully updated!`);
      });
    }
  };

  function renderRoleOptions(options: any[]) {
    return options.map((option: any) => {
      option.disabled = false;
      const assignmentParts = [applicationCode];
      if (currentResource) {
        if (currentResource?.parentResource?.code) {
          assignmentParts.push(currentResource?.parentResource?.code);
        }
        assignmentParts.push(currentResource?.code);
      }
      assignmentParts.push(
        roles
          .filter((role: any) => role.id === option.value)
          .map(({ code }) => code)
          .shift(),
      );
      const assignmentCode = assignmentParts.flat().join('.');

      if (assignmentMap.has(assignmentCode)) {
        const assignment = assignmentMap.get(assignmentCode);
        const lenient = formInstance.getFieldValue('lenient') === true;
        option.disabled = (option.value !== assignmentRuleSelected?.roleId && assignment?.users) || 0 > 0;
        if (create && lenient) option.disabled = false;
        if (option.disabled) option.extra = ` (${assignment.users} ${assignment.users === 1 ? 'user' : 'users'})`;
      }
      return option;
    });
  }

  return (
    <Page
      title={edit ? 'Edit Assignment Rule' : 'Assignment Rule'}
      extra={
        !edit &&
        checkPermissions(applicationId, 'applications:update') && (
          <Button key='edit-app' type='primary' onClick={() => setFromAppId(true)}>
            <Link to='edit'>Edit Rule</Link>
          </Button>
        )
      }
      titleIcon={<ThunderboltOutlined />}
      description={generalContent.pages.dynamicAssignments.pageDescription}
    >
      {loading && !create && <Spin style={{ position: 'fixed', left: '50%', top: '50%' }} />}
      {error && error?.status === 404 && <NotFoundPage {...error.data} />}
      <Form
        {...formItemLayout}
        labelCol={{ span: 6 }}
        onFinish={onFinish}
        wrapperCol={{ span: 18 }}
        form={formInstance}
        initialValues={assignmentRuleSelected}
      >
        <Row gutter={[24, 0]}>
          <Col {...colStyle}>
            <Form.Item
              name='name'
              label='Name'
              tooltip={{
                title: <div>Name</div>,
                overlayInnerStyle: { textAlign: 'center' },
              }}
              rules={[
                {
                  required: !readOnly,
                  pattern: new RegExp(/^(?!\s)(?![\w_ -]*\s\s)([a-zA-Z0-9. _-]{2,50})$/g),
                  message:
                    'Name required. It must be 2-50 characters long. No double or finishing with whitespace. No special characters but "-" or "_".',
                },
              ]}
              validateTrigger={['onChange', 'onBlur']}
              shouldUpdate
            >
              <Input disabled={disabled} className={fieldClassName} />
            </Form.Item>
            <Form.Item
              name='description'
              label='Description'
              tooltip={{
                title: <div>{generalContent.pages.dynamicAssignments.description}</div>,
                overlayInnerStyle: { textAlign: 'center' },
              }}
              shouldUpdate
            >
              <TextArea disabled={disabled} className={fieldClassName} />
            </Form.Item>
            <Form.Item
              label='Type'
              name='type'
              shouldUpdate
              tooltip={{
                title: <div>{generalContent.pages.dynamicAssignments.type}</div>,
                overlayInnerStyle: { textAlign: 'center' },
              }}
            >
              <Text code>Okta Group Membership Rule</Text>
              <Input hidden readOnly={true} disabled={true} className={'show-only-field'} />
            </Form.Item>
            <Form.Item
              name='oktaGroupId'
              label='Okta Group'
              tooltip={{
                title: <div>{generalContent.pages.dynamicAssignments.oktaGroupId}</div>,
                overlayInnerStyle: { textAlign: 'center' },
              }}
              className='field-disabled'
              rules={[
                {
                  required: !readOnly,
                },
              ]}
              validateTrigger={['onChange', 'onBlur']}
              shouldUpdate
            >
              <OktaGroupSelect
                readOnly={readOnly}
                applicationId={applicationId}
                className={create ? '' : 'show-only-field'}
              />
            </Form.Item>
          </Col>
          <Col {...colStyle}>
            <Form.Item
              label='lenient'
              name='lenient'
              shouldUpdate
              tooltip={{
                title: <div>{generalContent.pages.dynamicAssignments.lenient}</div>,
                overlayInnerStyle: { textAlign: 'center' },
              }}
              valuePropName='checked'
            >
              <Checkbox onChange={(e) => setLenient(e.target.checked)} disabled={readOnly} />
            </Form.Item>
            <Form.Item
              label='Resource'
              name='resourceId'
              shouldUpdate
              tooltip={{
                title: <div>{generalContent.pages.dynamicAssignments.resource}</div>,
                overlayInnerStyle: { textAlign: 'center' },
              }}
            >
              <ResourceSelect
                readOnly={readOnly}
                onChange={setResource}
                applicationId={applicationId}
                className={create ? '' : 'show-only-field'}
                currentResource={onResourceChange}
              />
            </Form.Item>
            <Form.Item
              label='Role'
              name='roleId'
              rules={[
                {
                  required: !readOnly,
                  message: 'The Role field is required.',
                },
              ]}
              shouldUpdate
              tooltip={{
                title: <div>{generalContent.pages.dynamicAssignments.role}</div>,
                overlayInnerStyle: { textAlign: 'center' },
              }}
            >
              <RolesSelect
                readOnly={readOnly}
                applicationId={applicationId}
                className={create ? '' : 'show-only-field'}
                onChange={setRole}
                currentRole={onRoleChange}
                allowedRoleIds={allowedRoleIds}
                renderOptions={renderRoleOptions}
              />
            </Form.Item>
            <Form.Item
              label='Assignment'
              name='code'
              tooltip={{
                title: <div>{generalContent.pages.dynamicAssignments.assignment}</div>,
                overlayInnerStyle: { textAlign: 'center' },
              }}
            >
              <Input readOnly={true} disabled={true} className={'show-only-field'} />
            </Form.Item>
            <Row hidden={readOnly} gutter={[8, 0]} style={{ float: 'right' }}>
              <Col>
                <Form.Item shouldUpdate>
                  <Button type='primary' disabled={false} danger>
                    <Link to={'..'}>Cancel</Link>
                  </Button>
                </Form.Item>
              </Col>
              <Col span={2}>
                <Form.Item shouldUpdate>
                  <Button
                    type='primary'
                    loading={saveAssignmentRuleModalLoading}
                    disabled={saveAssignmentRuleModalLoading}
                    htmlType='submit'
                  >
                    {create ? 'Create' : 'Save'}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      <Modal
        title='Alert'
        visible={visibleSaveAssignmentRuleModal}
        onOk={handleOk}
        okButtonProps={{ disabled: saveAssignmentRuleModalLoading }}
        okText={saveAssignmentRuleModalLoading ? 'Saving' : 'Save'}
        cancelButtonProps={{ disabled: saveAssignmentRuleModalLoading }}
        confirmLoading={saveAssignmentRuleModalLoading}
        onCancel={() => setVisibleSaveAssignmentRuleModal(false)}
      >
        <p>
          Do you want to {create ? 'create' : 'save'} the Assignment Rule
          <Text code>{assignmentRuleSelected.name}</Text>?
        </p>
      </Modal>
    </Page>
  );
};
