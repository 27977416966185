import { Menu, MenuProps, Tooltip } from 'antd';
import { createElement, FunctionComponent, useEffect, useState } from 'react';
import generalContent from '../conf/generalContent.json';
import usePermissions from 'hooks/usePermissions';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import { Link, useLocation, useParams } from 'react-router-dom';
import {
  ApartmentOutlined,
  ApiOutlined,
  AppstoreOutlined,
  ContainerOutlined,
  DesktopOutlined,
  ProjectOutlined,
  FileAddOutlined,
  FileTextOutlined,
  UserOutlined,
  ThunderboltOutlined,
} from '@ant-design/icons';
import { TMainMenuItem, useMenuStore } from 'store/menuStore';
import { Platform } from 'layouts/MainLayout';

const {
  applications,
  bulkImport,
  importList,
  users,
  resources,
  roles,
  oktaClients,
  assignments,
  inviteCodes,
  dynamicAssignments,
} = generalContent.pages;

export default (props: { isCollapsed: boolean; platform: Platform }) => {
  const { applicationId } = useParams();
  const menuStore = useMenuStore();
  const [menu, setMenu] = useState<TMainMenuItem[]>();
  const [menuItemSelectedKey, setMenuItemSelectedKey] = useState('1');
  const { checkPermissions } = usePermissions();
  const { md = true, lg, xs } = useBreakpoint();
  const { pathname } = useLocation();
  const { general } = generalContent;
  const { isCollapsed, platform } = props;
  const [visible, setVisible] = useState(false);

  const linkDocs = (
    <a href={general.linkDocumentation} target='_blank' rel='noreferrer'>
      Learn more.
    </a>
  );

  const tooltipsStylesMd = {
    marginTop: '24px',
    top: '254px',
    left: '68px',
  };

  const tooltipsStylesLg = {
    marginTop: '50px',
  };

  const menuElements: Record<
    Platform['key'],
    { name: string; description: string; icon: any; link: string; permission: string }[]
  > = {
    authManager: [
      {
        name: 'Applications',
        description: applications.pageDescription,
        icon: AppstoreOutlined,
        link: '',
        permission: 'applications:list',
      },
      {
        name: 'Users',
        description: users.pageDescription,
        icon: UserOutlined,
        link: 'users',
        permission: 'users:list',
      },
      {
        name: 'Resources',
        description: resources.pageDescription,
        icon: ProjectOutlined,
        link: 'resources',
        permission: 'resources:list',
      },
      {
        name: 'Roles',
        description: roles.pageDescription,
        icon: ApartmentOutlined,
        link: 'roles',
        permission: 'roles:list',
      },
      {
        name: 'Okta Clients',
        description: oktaClients.pageDescription,
        icon: DesktopOutlined,
        link: 'okta-clients',
        permission: 'clients:list',
      },
      {
        name: 'Assignments',
        description: assignments.pageDescription,
        icon: ApiOutlined,
        link: 'assignments',
        permission: 'assignments:list',
      },
      {
        name: 'Dynamic Assignments',
        description: dynamicAssignments.pageDescription,
        icon: ThunderboltOutlined,
        link: 'dynamic-assignments',
        permission: 'assignmentRule:list',
      },
      {
        name: 'Invite Codes',
        description: inviteCodes.pageDescription,
        icon: ContainerOutlined,
        link: 'invite-codes',
        permission: 'inviteCode:list',
      },
    ],
    // specific permissions are currently in TODO
    import: [
      {
        name: 'Braze Import',
        description: bulkImport.pageDescription,
        icon: FileAddOutlined,
        link: 'import',
        permission: 'inviteCode:list',
      },
      {
        name: 'List',
        description: importList.pageDescription,
        icon: FileTextOutlined,
        link: 'import/list',
        permission: 'inviteCode:list',
      },
    ],
  };

  const tooltipMenu = (tooltipText: string, link: string, label: string) => {
    return (
      <Tooltip
        placement={lg ? 'right' : 'left'}
        title={
          tooltipText && (
            <>
              {tooltipText} {linkDocs}
            </>
          )
        }
        overlayClassName={md && !isCollapsed ? '-toolTipLg' : '-toolTipMd'}
        overlayStyle={lg && !isCollapsed ? tooltipsStylesLg : tooltipsStylesMd}
        overlayInnerStyle={{ textAlign: 'center', whiteSpace: 'pre-line' }}
      >
        <Link
          onClick={() => setVisible(false)}
          to={platform.key === 'authManager' ? `${applicationId}/${link}` : `/${link}`}
          style={{ display: 'inline-block', width: '100%' }}
        >
          {(lg && !isCollapsed) || (!lg && !isCollapsed) || visible || !md ? label : `${label} - ${tooltipText}`}
        </Link>
      </Tooltip>
    );
  };

  function getItem(label: string, key: string, icon: FunctionComponent, link = '', tooltipText: string) {
    return {
      key,
      icon: createElement(icon),
      label: tooltipMenu(tooltipText, link, label),
      linklabel: label,
      link: !link?.length ? '/' : link,
    };
  }

  // Menu builder
  useEffect(() => {
    const menuItems: MenuProps['items'] = [];

    // Check permissions
    menuElements[platform.key].forEach((el, index: number) => {
      if (checkPermissions(applicationId, el.permission)) {
        menuItems.push(getItem(el.name, (index + 1).toString(), el.icon, el.link, el.description));
      }
    });

    setMenu(menuItems as TMainMenuItem[]);
  }, [applicationId, isCollapsed, xs, md, lg, platform.key]);

  // Active the menu item accordingly with the URL
  useEffect(() => {
    const urlArr = pathname.split('/');
    const urlTarget = urlArr[2];

    if (urlTarget && menu) {
      menu.map((el: any) => {
        if (el) {
          const elTarget = el.linklabel?.toLowerCase()?.replace(' ', '-');
          const elKey = el.key?.toString();
          if (urlTarget === elTarget) setMenuItemSelectedKey(elKey);
        }
      });
    } else {
      if (menu) setMenuItemSelectedKey(menu[0]?.key?.toString() || '1');
    }
  }, [pathname, menu]);

  // Menu - store
  useEffect(() => {
    if (menu && !menuStore.mainmenu.length) menuStore.setMainMenu(menu);
  }, [menu]);

  const menuApp = (
    <Menu mode='inline' style={{ height: '100%', borderRight: 0 }} items={menu} selectedKeys={[menuItemSelectedKey]} />
  );

  return menuApp;
};
