import { Button, Col, Form, Input, Modal, Row, Spin, Typography, notification } from 'antd';
import { useEffect, useState } from 'react';
import TextArea from 'antd/es/input/TextArea';
import { Page, ResourceSelect } from '../components';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import useResources, { IResource } from '../hooks/useResources';
import usePrevious from '../hooks/usePrevious';
import RolesSelect from '../components/RolesSelect';
import { NotFoundPage } from './index';
import generalContent from '../conf/generalContent.json';
import usePermissions from 'hooks/usePermissions';
import useRoles from 'hooks/useRoles';

const { Text } = Typography;
export default () => {
  const { applicationId = '', resourceId = '' } = useParams();
  const [resource, setResource] = useState<IResource>();
  const [fromAppId, setFromAppId] = useState(false);
  const [tempResource, setTempResource] = useState<IResource>(); // When editing, keep the old form values
  const [visibleSaveResourceModal, setVisibleSaveResourceModal] = useState(false);
  const [saveResourceModalLoading, setSaveResourceModalLoading] = useState(false);
  const [resourceName, setResourceName] = useState('');
  const [form] = Form.useForm();
  const { data, loading, getResource, error, updateResource, createResource } = useResources();
  const { getCompleteData: getRolesData, data: rolesData } = useRoles();
  const { pathname } = useLocation();
  const previousApp = usePrevious(applicationId);
  const navigate = useNavigate();
  const edit = pathname.endsWith('/edit');
  const create = pathname.endsWith('/create');
  const { checkPermissions } = usePermissions();
  const {
    name: nameTooltip,
    description: descriptionTooltip,
    parentResource: parentResourceTooltip,
    code: codeTooltip,
    allowedRoles: allowedRolesTooltip,
  } = generalContent.general.edit;

  const openNotificationWithIcon = (error: { message: string }) => {
    setVisibleSaveResourceModal(false);
    setSaveResourceModalLoading(false);
    notification.error({
      message: 'Action error!',
      description: error.message,
    });
  };

  useEffect(() => {
    return () => {
      form.setFieldsValue(tempResource);
    };
  }, [navigate]);

  useEffect(() => {
    // Redirect to resources page the user are in the edit page and change the application
    if (previousApp) navigate(`/${applicationId}/resources`);

    // Request resource by id
    if (applicationId && resourceId) getResource(applicationId, resourceId);
  }, [applicationId]);

  useEffect(() => {
    if (resource && rolesData) {
      // Get locked roles and unlocked roles that are already in the resource
      const allowedRoleIds = resource.allowedRoleIds;
      form.setFieldsValue({ ...resource, allowedRoleIds });
      setTempResource({ ...resource, allowedRoleIds });
    }
    setVisibleSaveResourceModal(false);
    setSaveResourceModalLoading(false);
  }, [resource, rolesData]);

  useEffect(() => {
    data && setResource(data as any);
  }, [data]);

  useEffect(() => {
    // Roles are used to filter out locked roles from a resource
    applicationId && getRolesData(applicationId);
  }, [applicationId]);

  useEffect(() => {
    if (error && error?.data && error?.data?.error) {
      let message = error?.data?.error;
      if (message.startsWith('".body.')) {
        const fieldDelimitation = message.indexOf('"', 7);
        const name = message.substring(7, fieldDelimitation);
        message = message.substring(fieldDelimitation + 1).trim();
        message = message.charAt(0).toUpperCase() + message.slice(1);
        form.setFields([{ name, errors: [message] }]);
        setVisibleSaveResourceModal(false);
        setSaveResourceModalLoading(false);
      } else {
        openNotificationWithIcon({ message });
      }
    }
  }, [error]);

  const onFinish = function (values: IResource) {
    setResourceName(values.name);
    setVisibleSaveResourceModal(true);
  };

  const handleOk = function () {
    // Gets fields values.Empty fields can be undefined. Empty allowedRoleIds means all roles
    const resource = form.getFieldsValue();
    resource.description ??= '';
    resource.allowedRoleIds ??= [];
    // Navigation - edit/add
    navigate(`/${applicationId}/resources/${resourceId}`, {
      replace: true,
      state: { resource, tempResource },
    });

    // The state 'resource' is only updated when data comes from the server, and after the user made changes and saved them
    // When updating it, the locked roles are included as well
    setResource({ ...resource, allowedRoleIds: [...resource.allowedRoleIds] });
    setTempResource(resource);

    if (!create && fromAppId) {
      setFromAppId(false);
      history.back();
    }

    setSaveResourceModalLoading(true);
    const success = (description: string) => {
      setVisibleSaveResourceModal(false);
      setSaveResourceModalLoading(false);
      notification.success({
        message: 'Success',
        description,
      });
    };

    // @TODO: Handle uncaught errors
    if (create) {
      createResource(applicationId, resource, () => {
        if (!(data instanceof Error)) {
          success(`Resource was successfully created!`);
          navigate(`/${applicationId}/resources`);
        }
      });
    } else {
      // When saving after editing, the locked roles are added again (if there was any)
      const resourceWithHiddenRoles = { ...resource, allowedRoleIds: resource.allowedRoleIds };
      updateResource(applicationId, resourceId, resourceWithHiddenRoles, (data) => {
        if (!(data instanceof Error)) success(`Resource was successfully updated!`);
      });
    }
  };

  const linkDocs = (
    <a href={generalContent.general.linkDocumentation} target='_blank' rel='noreferrer'>
      Learn more.
    </a>
  );

  const readOnly = !edit && !create;
  const disabled = readOnly || (!create && loading);
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const colStyle = {
    xs: 24,
    lg: 12,
    xl: 10,
    xxl: 8,
  };
  const fieldClassName = readOnly ? 'show-only-field' : '';
  return (
    <Page
      title={edit ? 'Edit resource' : 'Resource'}
      extra={
        readOnly &&
        !error &&
        checkPermissions(applicationId, 'resources:update') && (
          <Button key='edit-resource' type='primary' onClick={() => setFromAppId(true)}>
            <Link to='edit'>Edit Resource</Link>
          </Button>
        )
      }
    >
      {loading && !create && <Spin style={{ position: 'fixed', left: '50%', top: '50%' }} />}
      {error && error?.status === 404 && <NotFoundPage {...error.data} />}
      <Form
        {...formItemLayout}
        labelCol={{ span: 6 }}
        onFinish={onFinish}
        wrapperCol={{ span: 18 }}
        form={form}
        initialValues={{ ...tempResource }}
      >
        <Row gutter={[24, 0]}>
          <Col {...colStyle}>
            <Form.Item
              name='name'
              label='Name'
              tooltip={{
                title: (
                  <>
                    {nameTooltip} {linkDocs}
                  </>
                ),
                overlayInnerStyle: { textAlign: 'center' },
              }}
              rules={[
                {
                  required: !readOnly,
                  pattern: new RegExp(/^(?!\s)(?![\w_ -]*\s\s)([a-zA-Z0-9 _-]{2,50})$/g),
                  message:
                    'Name required. It must be 2-50 characters long. No double or finishing with whitespace. No special characters but "-" or "_".',
                },
              ]}
              validateTrigger={['onChange', 'onBlur']}
              shouldUpdate
            >
              <Input disabled={disabled} className={fieldClassName} />
            </Form.Item>
            <Form.Item
              name='description'
              label='Description'
              tooltip={{
                title: (
                  <>
                    {descriptionTooltip} {linkDocs}
                  </>
                ),
                overlayInnerStyle: { textAlign: 'center' },
              }}
              shouldUpdate
            >
              <TextArea disabled={disabled} className={fieldClassName} />
            </Form.Item>
            <Form.Item
              name={['parentResource', 'id']}
              label='Parent Resource:'
              tooltip={{
                title: (
                  <>
                    {parentResourceTooltip}
                    <br />
                    {linkDocs}
                  </>
                ),
                overlayInnerStyle: { textAlign: 'center' },
              }}
              labelCol={{ xs: { span: 24 }, sm: { span: 6 }, lg: { span: 8, offset: 0 } }}
              wrapperCol={{ xs: { span: 24 }, sm: { span: 18 }, lg: { span: 16 } }}
              shouldUpdate
            >
              <ResourceSelect applicationId={applicationId} loading={loading} readOnly={readOnly} />
            </Form.Item>
          </Col>
          <Col {...colStyle}>
            <Form.Item
              name='code'
              label='Code'
              className='field-disabled'
              tooltip={{
                title: (
                  <>
                    {codeTooltip} {linkDocs}
                  </>
                ),
                overlayInnerStyle: { textAlign: 'center' },
              }}
              rules={[
                {
                  required: create,
                  pattern: new RegExp(/^[a-zA-Z0-9-_]{2,50}$/),
                  message:
                    'Field code required. It must be 2-50 characters long, with no whitespace or special character but "-" or "_".',
                },
              ]}
              validateTrigger={['onChange', 'onBlur']}
              shouldUpdate
            >
              <Input disabled={!create} className={create ? '' : 'show-only-field'} />
            </Form.Item>
            <Form.Item
              labelCol={{ xs: { span: 24 }, sm: { span: 6 }, lg: { span: 8, offset: 0 } }}
              wrapperCol={{ xs: { span: 24 }, sm: { span: 18 }, lg: { span: 16 } }}
              label='Allowed roles'
              name='allowedRoleIds'
              tooltip={{
                title: (
                  <>
                    {allowedRolesTooltip} {linkDocs}
                  </>
                ),
                overlayInnerStyle: { textAlign: 'center' },
              }}
              shouldUpdate
            >
              <RolesSelect
                mode={'multiple'}
                className={fieldClassName}
                readOnly={readOnly}
                applicationId={applicationId}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row hidden={readOnly} gutter={[8, 0]}>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Form.Item wrapperCol={{ sm: 24, xl: 20, xxl: 16 }} shouldUpdate>
              <Button
                type='primary'
                loading={saveResourceModalLoading}
                disabled={saveResourceModalLoading}
                htmlType='submit'
              >
                {create ? 'Create' : 'Save'}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Modal
        title='Alert'
        visible={visibleSaveResourceModal}
        onOk={handleOk}
        okButtonProps={{ disabled: saveResourceModalLoading }}
        okText={saveResourceModalLoading ? 'Saving' : 'Save'}
        cancelButtonProps={{ disabled: saveResourceModalLoading }}
        confirmLoading={saveResourceModalLoading}
        onCancel={() => setVisibleSaveResourceModal(false)}
      >
        <p>
          Do you want to {create ? 'create' : 'save'} the resource<Text code>{resourceName}</Text>?
        </p>
      </Modal>
    </Page>
  );
};
