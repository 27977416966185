import { Select } from 'antd';
import { useEffect, useState } from 'react';
import useRoles, { IRole } from '../hooks/useRoles';
import { LockOutlined } from '@ant-design/icons';

export default (props: {
  mode?: 'multiple' | 'tags';
  value?: any;
  allowedRoleIds?: string[];
  applicationId: string;
  readOnly?: boolean;
  className?: string;
  loading?: boolean;
  valueIndex?: keyof Omit<IRole, 'locked'>;
  onChange?: (value: string, id: string) => void;
  renderOptions?: (options: any[]) => { label: string; value: string; disabled?: boolean }[];
  currentRole?: (resource: IRole) => void;
}) => {
  const {
    mode,
    readOnly = false,
    className = '',
    allowedRoleIds,
    applicationId,
    value,
    valueIndex = 'id',
    onChange = () => undefined,
    renderOptions,
  } = props;
  const [roles, setRoles] = useState<{ label: string; value: string; id: string }[]>([]);
  const { data, loadingRecursive, getCompleteData } = useRoles();

  const [rolesObjects, setRolesObjects] = useState<IRole[]>([]);
  const [selectedRole, setSelectedRole] = useState<IRole>();

  useEffect(() => {
    if (value && roles.length) {
      setSelectedRole(rolesObjects.find((role: IRole) => role[valueIndex || 'id'] === value));
    }
  }, [roles, value]);

  useEffect(() => {
    if (props?.currentRole && selectedRole) {
      props?.currentRole(selectedRole);
    }
  }, [selectedRole, value]);

  useEffect(() => {
    applicationId && getCompleteData(applicationId);
  }, [applicationId]);

  /**
   * When allowedRoleIds[] is empty, all the roles are displayed (except locked roles).
   * Otherwise, only roles that are in the allowedRoleIds[] will be displayed (except locked roles).
   * allowedRoleIds[] is empty when no resource was selected, or when the selected resource has no allowed roles.
   */
  useEffect(() => {
    const items: IRole[] = data?.items || [];
    setRolesObjects(items);
    setRoles(
      items
        .filter((role) => !allowedRoleIds?.length || allowedRoleIds.includes(role.id))
        .map((role) => ({ label: role.name, value: role[valueIndex], id: role.id })),
    );
  }, [allowedRoleIds, loadingRecursive, valueIndex]);

  // When onChange is called, the selected value and its id is passed to the onChange function
  return (
    <Select
      {...{ mode }}
      allowClear
      value={value}
      className={className}
      showSearch
      loading={loadingRecursive}
      disabled={readOnly || loadingRecursive}
      style={{ width: '100%' }}
      placeholder={!readOnly && 'Search and Select a Role'}
      onChange={(value) => onChange(value, roles.find((role) => role.value === value)?.id || '')}
      options={
        typeof renderOptions === 'function'
          ? renderOptions(roles).map((item: any) => ({
              ...item,
              label: item.disabled ? (
                <>
                  <LockOutlined />
                  {` ${item.label}${item?.extra || ''}`}
                </>
              ) : (
                `${item.label}${item?.extra || ''}`
              ),
            }))
          : (roles as any)
      }
      filterOption={(inputValue, option) => {
        const label = `${option?.label || ''}`;
        const labelUp: string = label?.toUpperCase() || '';
        return labelUp?.indexOf(inputValue.toUpperCase()) !== -1;
      }}
    ></Select>
  );
};
