import generalContent from '../conf/generalContent.json';
import { MainLayout } from '../layouts';
import { Navigate } from 'react-router-dom';
import {
  ApplicationEditPage,
  AssignmentManagementPage,
  AssignmentsPage,
  AuthenticationPage,
  ImportPage,
  ImportStatusPage,
  ImportListPage,
  HomePage,
  InviteCodeEditPage,
  InviteCodesPage,
  NotFoundPage,
  OktaClientsEditPage,
  OktaClientsPage,
  ResourceEditPage,
  ResourcePage,
  RoleEditPage,
  RolesPage,
  UserEditPage,
  UserPage,
  AssignmentRulesPage,
  AssignmentRulesEditPage,
} from '../pages';
import { LoginCallback } from '@okta/okta-react';
import RequireAuth from 'components/RequireAuth';

export default [
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        index: true,
        element: <HomePage />,
        breadcrumbName: 'Applications',
      },
      {
        path: ':applicationId',
        element: <HomePage />,
        breadcrumbName: 'Applications',
        backButton: false,
      },
      {
        path: ':applicationId/:applicationCode',
        breadcrumbName: 'Application',
        element: <ApplicationEditPage />,
      },
      {
        path: ':applicationId/:applicationCode/edit',
        breadcrumbName: 'Application Edit',
        element: (
          <RequireAuth permissions={['applications:update']}>
            <ApplicationEditPage />
          </RequireAuth>
        ),
      },
      {
        path: ':applicationId/assignments',
        breadcrumbName: 'Assignments',
        children: [
          {
            index: true,
            element: (
              <RequireAuth permissions={['assignments:list']}>
                <AssignmentsPage />
              </RequireAuth>
            ),
          },
          {
            path: ':assignmentId',
            element: (
              <RequireAuth permissions={['assignments:list']}>
                <AssignmentManagementPage type='users' />
              </RequireAuth>
            ),
          },
          {
            path: ':assignmentId/users',
            element: (
              <RequireAuth permissions={['assignments:list']}>
                <AssignmentManagementPage type='users' />
              </RequireAuth>
            ),
            breadcrumbName: 'Users',
          },
          {
            path: ':assignmentId/clients',
            element: (
              <RequireAuth permissions={['assignments:list']}>
                <AssignmentManagementPage type='clients' />
              </RequireAuth>
            ),
            breadcrumbName: 'Clients',
          },
        ],
      },
      {
        path: ':applicationId/resources',
        breadcrumbName: 'Resources',
        children: [
          {
            index: true,
            element: (
              <RequireAuth permissions={['resources:list']}>
                <ResourcePage />
              </RequireAuth>
            ),
          },
          {
            path: ':resourceId',
            element: (
              <RequireAuth permissions={['resources:list']}>
                <ResourceEditPage />
              </RequireAuth>
            ),
            breadcrumbName: 'Resource',
          },
          {
            path: 'create',
            element: (
              <RequireAuth permissions={['resources:list', 'resources:create']}>
                <ResourceEditPage />
              </RequireAuth>
            ),
            breadcrumbName: 'Create Resource',
          },
          {
            path: ':resourceId/edit',
            element: (
              <RequireAuth permissions={['resources:list', 'resources:update']}>
                <ResourceEditPage />
              </RequireAuth>
            ),
            breadcrumbName: 'Resource Edit',
          },
        ],
      },
      {
        path: ':applicationId/users',
        breadcrumbName: 'Users',
        children: [
          {
            index: true,
            element: (
              <RequireAuth permissions={['users:list']}>
                <UserPage />
              </RequireAuth>
            ),
            breadcrumbName: 'Users',
          },
          {
            path: ':userId',
            element: (
              <RequireAuth permissions={['users:list']}>
                <UserEditPage />
              </RequireAuth>
            ),
            breadcrumbName: 'User',
          },
          {
            path: ':userId/edit',
            element: (
              <RequireAuth permissions={['users:list']}>
                <UserEditPage />
              </RequireAuth>
            ),
            breadcrumbName: 'User Editor',
          },
        ],
      },
      {
        path: ':applicationId/roles',
        breadcrumbName: 'Roles',
        children: [
          {
            index: true,
            element: (
              <RequireAuth permissions={['roles:list']}>
                <RolesPage />
              </RequireAuth>
            ),
            breadcrumbName: 'Roles',
          },
          {
            path: ':roleId',
            element: (
              <RequireAuth permissions={['roles:list']}>
                <RoleEditPage />
              </RequireAuth>
            ),
            breadcrumbName: 'Role',
          },
          {
            path: ':roleId/edit',
            element: (
              <RequireAuth permissions={['roles:list', 'roles:update']}>
                <RoleEditPage />
              </RequireAuth>
            ),
            breadcrumbName: 'Role Editor',
          },
          {
            path: 'create',
            element: (
              <RequireAuth permissions={['roles:list', 'roles:create']}>
                <RoleEditPage />
              </RequireAuth>
            ),
            breadcrumbName: 'Create Role',
          },
        ],
      },
      {
        path: ':applicationId/okta-clients',
        breadcrumbName: 'Okta Clients',
        children: [
          {
            index: true,
            element: (
              <RequireAuth permissions={['clients:list']}>
                <OktaClientsPage />
              </RequireAuth>
            ),
            breadcrumbName: 'Clients',
          },
          {
            path: ':clientId',
            element: (
              <RequireAuth permissions={['clients:list']}>
                <OktaClientsEditPage />
              </RequireAuth>
            ),
            breadcrumbName: 'Client',
          },
          {
            path: ':clientId/edit',
            element: (
              <RequireAuth permissions={['clients:list']}>
                <OktaClientsEditPage />
              </RequireAuth>
            ),
            breadcrumbName: 'Client Editor',
          },
          {
            path: 'create',
            element: (
              <RequireAuth permissions={['clients:list']}>
                <OktaClientsEditPage />
              </RequireAuth>
            ),
            breadcrumbName: 'Create Client',
          },
        ],
      },
      {
        path: ':applicationId/invite-codes',
        breadcrumbName: 'Invite Codes',
        children: [
          {
            index: true,
            element: (
              <RequireAuth permissions={['inviteCode:list']}>
                <InviteCodesPage />
              </RequireAuth>
            ),
            breadcrumbName: 'Invite Codes',
          },
          {
            path: ':invideCodeId',
            element: (
              <RequireAuth permissions={['inviteCode:list']}>
                <InviteCodeEditPage />
              </RequireAuth>
            ),
            breadcrumbName: 'Invite Code',
          },
          {
            path: ':invideCodeId/edit',
            element: (
              <RequireAuth permissions={['inviteCode:list', 'inviteCode:update']}>
                <InviteCodeEditPage />
              </RequireAuth>
            ),
            breadcrumbName: 'Invite Code Editor',
          },
          {
            path: 'create',
            element: (
              <RequireAuth permissions={['inviteCode:list', 'inviteCode:create']}>
                <InviteCodeEditPage />
              </RequireAuth>
            ),
            breadcrumbName: 'Create Invite',
          },
        ],
      },
      {
        path: ':applicationId/dynamic-assignments',
        breadcrumbName: 'Dynamic Assignments',
        children: [
          {
            index: true,
            element: (
              <RequireAuth permissions={['assignmentRule:list']}>
                <AssignmentRulesPage />
              </RequireAuth>
            ),
            breadcrumbName: 'Dynamic Assignments',
          },
          {
            path: ':assignmentRuleId',
            element: (
              <RequireAuth permissions={['assignmentRule:list']}>
                <AssignmentRulesEditPage />
              </RequireAuth>
            ),
            breadcrumbName: 'Dynamic Assignment',
          },
          {
            path: ':assignmentRuleId/edit',
            element: (
              <RequireAuth permissions={['assignmentRule:list', 'assignmentRule:update']}>
                <AssignmentRulesEditPage />
              </RequireAuth>
            ),
            breadcrumbName: 'Editor',
          },
          {
            path: 'create',
            element: (
              <RequireAuth permissions={['assignmentRule:create']}>
                <AssignmentRulesEditPage />
              </RequireAuth>
            ),
            breadcrumbName: 'Create',
          },
        ],
      },
      {
        path: 'import',
        breadcrumbName: generalContent.pages.bulkImport.usersTitle,
        children: [
          {
            index: true,
            element: (
              <RequireAuth
                permissions={
                  [
                    /*pending*/
                  ]
                }
              >
                <ImportPage resource='users' />
              </RequireAuth>
            ),
            breadcrumbName: generalContent.pages.bulkImport.usersTitle,
          },
          {
            path: ':syncId',
            element: (
              <RequireAuth
                permissions={
                  [
                    /*pending*/
                  ]
                }
              >
                <ImportStatusPage />
              </RequireAuth>
            ),
            breadcrumbName: generalContent.pages.importStatus.title,
          },
          {
            path: 'list',
            element: (
              <RequireAuth
                permissions={
                  [
                    /*pending*/
                  ]
                }
              >
                <ImportListPage />
              </RequireAuth>
            ),
            breadcrumbName: generalContent.pages.importList.title,
          },
        ],
      },
    ],
  },

  {
    path: '*',
    element: <NotFoundPage />,
    breadcrumbName: 'Not found',
  },
  {
    path: 'implicit/callback',
    element: <LoginCallback loadingElement={<AuthenticationPage defaultMessage={'loading'} />} />,
  },
  {
    path: 'implicit/logout',
    element: <Navigate to='/' />,
  },
];
