import { Select } from 'antd';
import { useEffect, useState } from 'react';
import useGroups, { IOktaGroup } from '../hooks/useOktaGroups';

const { Option } = Select;

export default (props: {
  mode?: 'multiple' | 'tags';
  value?: any;
  applicationId: string;
  readOnly?: boolean;
  className?: string;
  loading?: boolean;
  valueIndex?: keyof IOktaGroup;
  onChange?: (value: string, id: string) => void;
  renderOptions?: (options: any[]) => { label: string; value: string; disabled?: boolean }[];
  currentGroup?: (oktaGroup: IOktaGroup | null) => void;
}) => {
  const {
    mode,
    readOnly = false,
    className = '',
    applicationId,
    value,
    valueIndex = 'id',
    onChange = () => undefined,
    renderOptions,
  } = props;
  const [Groups, setGroups] = useState<{ label: string; value: string; id: string }[]>([]);
  const { data, getAll, loading } = useGroups();

  const [GroupsObjects, setGroupsObjects] = useState<IOktaGroup[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<IOktaGroup>();

  useEffect(() => {
    if (value && Groups.length) {
      setSelectedGroup(GroupsObjects.find((Group: IOktaGroup) => Group[valueIndex || 'id'] === value));
    }
  }, [Groups]);

  useEffect(() => {
    if (props?.currentGroup && selectedGroup) {
      props?.currentGroup(value ? selectedGroup : null);
    }
  }, [!!selectedGroup, value]);

  useEffect(() => {
    applicationId && getAll(applicationId);
  }, [applicationId]);

  useEffect(() => {
    const items: IOktaGroup[] = data?.items || [];
    setGroupsObjects(items);
    setGroups(items.map((Group) => ({ label: Group.name, value: Group[valueIndex], id: Group.id })));
  }, [data, valueIndex]);

  // When onChange is called, the selected value and its id is passed to the onChange function
  return (
    <Select
      {...{ mode }}
      allowClear
      value={value}
      className={className}
      showSearch
      loading={loading}
      disabled={readOnly}
      style={{ width: '100%' }}
      placeholder={!readOnly && 'Search and Select a Group'}
      onChange={(value) => onChange(value, Groups.find((Group) => Group.value === value)?.id || '')}
      options={Groups}
      filterOption={(inputValue, option) => {
        const label: string = option?.label as string;
        const labelUp: string = label?.toUpperCase() || '';
        return labelUp?.indexOf(inputValue.toUpperCase()) !== -1;
      }}
    >
      {renderOptions &&
        renderOptions(Groups || []).map((item) => (
          <Option key={item.value} value={item.value} disabled={item.disabled || false}>
            {item.label}
          </Option>
        ))}
    </Select>
  );
};
