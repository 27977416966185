import useAxios from './useAxios';

export interface IAssignmentRuleItem {
  id: string;
  name: string;
  description: string;
  code?: string;
  dynamic: boolean;
  type: number;
  lenient: boolean;
  oktaGroupId: string;
  applicationId: string;
  resourceId?: string;
  roleId: string;
}

export interface IAssignmentRuleEdit {
  id?: string;
  name: string;
  description: string;
  code?: string;
  type: number;
  lenient: boolean;
  oktaGroupId: string;
  applicationId: string;
  resourceId?: string;
  roleId: string;
}

export default (): {
  data: any;
  error: any;
  loading: boolean;
  getAllAssignmentRules: (applicationCode: string) => Promise<IAssignmentRuleItem[]>;
  getAllWithLimit: (
    applicationId: string,
    limit: number,
    offset?: number,
    sort?: string,
  ) => Promise<IAssignmentRuleItem[]>;
  getAssignmentRule: (applicationId: string, assignmentRuleId: string) => void;
  createAssignmentRule: (
    applicationId: string,
    assignmentRule: IAssignmentRuleItem,
    callback: (data: IAssignmentRuleItem) => void,
  ) => Promise<any>;
  updateAssignmentRule: (
    applicationId: string,
    assignmentRuleId: string,
    assignmentRuleItem: IAssignmentRuleEdit,
    callback: (data: any) => void,
  ) => Promise<any>;
  removeAssignmentRule: (
    applicationId: string,
    assignmentRuleId: string,
    callback: (data: IAssignmentRuleItem) => void,
  ) => Promise<any>;
} => {
  const { data, loading = false, error, requestAsync } = useAxios();

  const getAllWithLimit = async (
    applicationId: string,
    limit: number,
    offset?: number,
    sort?: string,
    cb?: (data: any) => void,
  ): Promise<any> => {
    const genericCb = (payload: any) => payload?.data?.items || [];
    return requestAsync(
      `/applications/${applicationId}/assignment-rules/?sort=${sort}&limit=${limit}&offset=${offset || 0}`,
    ).then(cb || genericCb);
  };

  const getAssignmentRule = (applicationId: string, assignmentRuleId: string) => {
    return requestAsync(`/applications/${applicationId}/assignment-rules/${assignmentRuleId}`);
  };

  const getAllAssignmentRules = async (applicationCode: string) => {
    let total = 0;
    let offset = 0;
    let items: any[] = [];
    const limit = 1000;
    const sort = 'code asc';

    await getAllWithLimit(applicationCode, limit, offset, sort, (payload: any) => {
      items = [...(payload?.data?.items || [])];
      total = payload?.data?.total || 0;
      offset = items.length || 0;
    });

    if (total > offset) {
      await getAllWithLimit(applicationCode, total - offset, offset, sort, (payload: any) => {
        items = [...items, ...payload.data.items];
      });
    }

    return items;
  };

  const createAssignmentRule = async (
    applicationId: string,
    assignmentRuleItem: IAssignmentRuleItem,
    callback: (data: any) => void,
  ) => {
    assignmentRuleItem?.code && delete assignmentRuleItem?.code;
    assignmentRuleItem?.resourceId === '' && delete assignmentRuleItem?.resourceId;
    return requestAsync(`/applications/${applicationId}/assignment-rules`, 'post', assignmentRuleItem, callback);
  };

  const updateAssignmentRule = async (
    applicationId: string,
    assignmentRuleId: string,
    assignmentRuleItem: IAssignmentRuleEdit,
    callback: (data: any) => void,
  ) => {
    assignmentRuleItem?.code && delete assignmentRuleItem?.code;
    assignmentRuleItem?.resourceId === '' && delete assignmentRuleItem?.resourceId;
    return requestAsync(
      `/applications/${applicationId}/assignment-rules/${assignmentRuleId}`,
      'put',
      assignmentRuleItem,
      callback, // callBack
    );
  };

  const removeAssignmentRule = async (
    applicationId: string,
    assignmentRuleId: string,
    callback: (data: IAssignmentRuleItem) => void,
  ) => {
    return requestAsync(`/applications/${applicationId}/assignment-rules/${assignmentRuleId}`, 'delete', {}, callback);
  };

  return {
    data,
    error,
    loading,
    getAllWithLimit,
    getAssignmentRule,
    createAssignmentRule,
    updateAssignmentRule,
    getAllAssignmentRules,
    removeAssignmentRule,
  };
};
