import { Button, Col, Descriptions, Form, Input, List, Modal, notification, Row, Skeleton, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Page, ResourceSelect } from '../components';
import useClients, { IClient } from '../hooks/useClients';
import RolesSelect from '../components/RolesSelect';
import usePermissions from 'hooks/usePermissions';
import usePrevious from '../hooks/usePrevious';

const { Title, Text } = Typography;
export default () => {
  const { pathname } = useLocation();
  const { applicationId = '', clientId = '' } = useParams();
  const edit = pathname.endsWith('/edit');
  const [client, setClient] = useState<IClient>({
    assignments: [],
    created: '',
    id: '',
    label: '',
    name: '',
    platformUserId: '',
    status: '',
  });
  const [resource, setResource] = useState('');
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [deleteModalLoading, setDeleteModalLoading] = useState(false);
  const [deleteAssignment, setDeleteAssignment] = useState('');
  const [formInstance] = Form.useForm();
  const { data, loading, error, get, getAssignments, assignments, loadingAssignment, addAssignment, removeAssignment } =
    useClients();
  const { checkPermissions } = usePermissions();
  const previousApp = usePrevious(applicationId);
  const navigate = useNavigate();

  const loadClient = () => {
    get(applicationId, clientId);
    getAssignments(applicationId, clientId);
  };

  const openNotificationWithIcon = (error: { message: string }) => {
    setVisibleDeleteModal(false);
    setDeleteModalLoading(false);
    notification.error({
      message: 'Action error!',
      description: error.message,
    });
  };

  useEffect(() => {
    if (previousApp) {
      let url = `/${applicationId}/okta-clients/${clientId}`;
      if (edit) url += `/edit`;
      navigate(url);
      loadClient();
    }
  }, [applicationId]);

  useEffect(() => {
    !data && loadClient();

    if (data && data.items.length) {
      setClient(data.items[0]);
    }
  }, [data]);

  useEffect(() => {
    formInstance.resetFields(['role']);
  }, [resource]);

  const addAssignmentToClient = (values: any) => {
    const { result: assignmentCode } = values;
    addAssignment(applicationId, clientId, assignmentCode).then(() => {
      formInstance.resetFields(['resource', 'role', 'result']);
    });
  };

  useEffect(() => {
    if (error && error?.data && error?.data?.error) {
      let message = error?.data?.error;
      if (message.startsWith('".body.')) {
        const fieldDelimitation = message.indexOf('"', 7);
        const name = message.substring(7, fieldDelimitation);
        message = message.substring(fieldDelimitation + 1).trim();
        message = message.charAt(0).toUpperCase() + message.slice(1);
        formInstance.setFields([{ name, errors: [message] }]);
        setVisibleDeleteModal(false);
        setVisibleDeleteModal(false);
      } else {
        openNotificationWithIcon({ message });
      }
    }
  }, [error]);

  const onValuesChange = function (_prev: any, values: any) {
    const { role } = values;
    if (role) {
      formInstance.setFieldValue(
        'result',
        resource ? `${applicationId}.${resource}.${role}` : `${applicationId}.${role}`,
      );
    }
  };

  const handleRemoveAssignment = function () {
    setDeleteModalLoading(true);
    removeAssignment(applicationId, clientId, deleteAssignment).then(() => {
      setVisibleDeleteModal(false);
      setDeleteModalLoading(false);
    });
  };

  const deleteAssignmentModal = (assignment: string) => {
    setVisibleDeleteModal(true);
    setDeleteAssignment(assignment);
  };
  function renderRolesOptions(options: { label: string; value: string }[]) {
    const assignmentsCodes = (assignments || []).map((assignment: any) => assignment.code);
    if (!assignmentsCodes) return options;
    return options.map(({ value, label }) => {
      const disabled =
        assignmentsCodes.indexOf(resource ? `${applicationId}.${resource}.${value}` : `${applicationId}.${value}`) >= 0;
      return {
        value,
        label: disabled ? `${label} (Already Added)` : label,
        disabled,
      };
    });
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const colStyle = {
    xs: 24,
    md: 12,
    xl: 8,
    xxl: 6,
  };

  const buttons = (
    <Button type='primary'>
      <Link to={'edit'}>Edit</Link>
    </Button>
  );
  return (
    <Page title={edit ? 'Edit client' : 'Client'} extra={!edit && buttons}>
      <Row style={{ marginBottom: '30px' }}>
        <Descriptions size='small' column={1}>
          <Descriptions.Item label={<Text strong>Label</Text>}>
            {loading ? <Skeleton.Input active size={'small'} /> : client?.label}
          </Descriptions.Item>
          <Descriptions.Item label={<Text strong>Name</Text>}>
            {loading ? <Skeleton.Input active size={'small'} /> : client?.name}
          </Descriptions.Item>
          <Descriptions.Item label={<Text strong>Client ID</Text>}>
            {loading ? <Skeleton.Input active size={'small'} /> : client?.id}
          </Descriptions.Item>
        </Descriptions>
      </Row>
      <Row gutter={[16, 16]} style={{ paddingBottom: '24px' }}>
        {checkPermissions(applicationId, 'assignments:create') && (
          <Col {...colStyle} hidden={!edit}>
            <Form
              {...formItemLayout}
              form={formInstance}
              onValuesChange={onValuesChange}
              onFinish={addAssignmentToClient}
              initialValues={{ ...client }}
            >
              <Title level={5}>Add Role</Title>
              <Form.Item label='Resource' name='resource' shouldUpdate>
                <ResourceSelect onChange={setResource} applicationId={applicationId} valueIndex={'code'} />
              </Form.Item>
              <Form.Item name='role' label='Roles' rules={[{ required: true }]} shouldUpdate>
                <RolesSelect
                  loading={loadingAssignment}
                  valueIndex={'code'}
                  applicationId={applicationId}
                  renderOptions={renderRolesOptions}
                />
              </Form.Item>
              <Form.Item shouldUpdate style={{ float: 'right' }}>
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={loadingAssignment}
                  disabled={loadingAssignment}
                  hidden={!edit}
                >
                  Add
                </Button>
              </Form.Item>
              <Form.Item name='result'>
                <Input hidden />
              </Form.Item>
            </Form>
          </Col>
        )}
        <Col {...colStyle}>
          <Title level={5}>Assignments:</Title>
          <List loading={loadingAssignment} bordered style={{ width: '100%', minHeight: '53px' }}>
            {(assignments || []).length
              ? assignments.map((assignment: any, index: number) => (
                  <List.Item
                    key={`${assignment.code}-${index}`}
                    extra={
                      edit &&
                      checkPermissions(applicationId, 'assignments:delete') && (
                        <DeleteOutlined
                          onClick={() => deleteAssignmentModal(assignment.code)}
                          style={{ paddingRight: '20px' }}
                        />
                      )
                    }
                  >
                    {assignment.code}
                  </List.Item>
                ))
              : !loadingAssignment && <List.Item>No Assignment</List.Item>}
          </List>
          <Modal
            title='Alert'
            visible={visibleDeleteModal}
            onOk={handleRemoveAssignment}
            okButtonProps={{ danger: true, disabled: deleteModalLoading }}
            okText={deleteModalLoading ? 'Removing' : 'Remove'}
            cancelButtonProps={{ disabled: deleteModalLoading }}
            confirmLoading={deleteModalLoading}
            onCancel={() => setVisibleDeleteModal(false)}
          >
            <p>
              Do you want to remove the assignment<Text code>{deleteAssignment}</Text>?
            </p>
          </Modal>
        </Col>
      </Row>
    </Page>
  );
};
