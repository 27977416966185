import { Table, Space, Typography } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import { IUser } from '../hooks/useUsers';
import { AssignmentsTagList } from './index';
import PaginationGeneric from './PaginationGeneric';
import { useEffect, useState } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';

const { Column } = Table;
const { Text } = Typography;

export default (props: {
  users: IUser[];
  loading: boolean;
  hideAssignments?: boolean;
  onFinalPage?: (applicationId: string, cursor: string) => void;
  total?: number;
  cursorNext?: string;
  onPageChange?: (val: number, pageSize: number) => void;
  onSizeChange?: (val: number, pageSize: number) => void;
  onPaginationLoad?: (settings: { pageSizeInner: number; currentPage: number }) => void;
}) => {
  const { applicationId = '' } = useParams<{ applicationId: string }>();
  const { onFinalPage, cursorNext, onPageChange, onPaginationLoad, onSizeChange } = props;
  const [usersArr, setUsersArr] = useState<IUser[]>();

  const [lsPageSize] = useLocalStorage<number>('am-page-size', 10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeInner, setPageSizeInner] = useState(lsPageSize);

  const pageChange = (currentPage: number, pageSize: number, totalPages: number) => {
    setCurrentPage(currentPage);
    setPageSizeInner(pageSize);
    if (cursorNext || (props?.total || 0) > 0) {
      const finalPage = totalPages === currentPage * pageSize;
      if (finalPage && typeof onFinalPage === 'function') {
        onFinalPage(applicationId, cursorNext || '');
      }
    } else {
      if (typeof onPageChange === 'function') onPageChange(currentPage, pageSize);
    }
  };

  const onLoad = (settings: any) => {
    if (typeof onPaginationLoad === 'function') onPaginationLoad({ ...settings, currentPage });
  };

  const sizeChange = (currentPage: number, pageSize: number) => {
    if (typeof onSizeChange === 'function') onSizeChange(currentPage, pageSize);
  };

  useEffect(() => {
    const mapUsers = props.users.map((user: any) => ({ ...user, key: `user-${user?.id}` }));
    setUsersArr(mapUsers);
  }, [props.users]);

  const calPage = (currentPage - 1) * pageSizeInner;
  let usersToShow = usersArr?.slice(calPage, calPage + pageSizeInner);

  useEffect(() => {
    if (usersArr && usersArr.length > pageSizeInner) {
      usersToShow = usersArr?.slice(calPage, calPage + pageSizeInner);
      if (!usersToShow?.length) {
        pageChange(1, pageSizeInner, props.users?.length);
      }
    } else {
      usersToShow = usersArr;
      if (usersToShow?.length) setCurrentPage(1);
    }
  }, [usersToShow]);

  useEffect(() => {
    const paginationTotal: any = document.querySelector<HTMLElement>('.ant-pagination-simple-pager');

    // Workaround to remove pagination total as we don't have it for Users.
    if (paginationTotal !== null) {
      const inputPag = paginationTotal?.firstElementChild;
      paginationTotal.style.pointerEvents = 'none';
      paginationTotal.textContent = '';
      if (inputPag) paginationTotal.appendChild(inputPag);
    }
  }, [props.cursorNext]);

  useEffect(() => {
    if (usersArr?.length === 0) {
      setCurrentPage(1);
    }
  }, [usersArr]);

  return (
    <>
      <Table dataSource={usersToShow} pagination={false} loading={props.loading}>
        <Column
          title='Name'
          render={({ id, profile, valid }) =>
            valid ? (
              <Link to={`/${applicationId}/users/${id}`}>{`${profile.firstName} ${profile.lastName}`}</Link>
            ) : (
              <Text italic disabled>
                {id} (Not exist in Okta)
              </Text>
            )
          }
        />
        <Column
          title='Email'
          render={({ id, profile, valid }) =>
            valid && <Link to={`/${applicationId}/users/${id}`}>{profile.email}</Link>
          }
        />
        {!props?.hideAssignments && (
          <Column title='Assignments' render={(user) => <AssignmentsTagList user={user} />} />
        )}
        <Column
          title='Actions'
          key='action'
          align='center'
          render={(user) => {
            return (
              <Space size='middle'>
                <Link to={`/${applicationId}/users/${user.id}/edit`}>
                  <EditOutlined style={{ fontSize: '18px' }} />
                </Link>
              </Space>
            );
          }}
        />
      </Table>
      <div className='pagination-generic' key={cursorNext}>
        <PaginationGeneric
          loading={props.loading}
          total={props?.total || props.users.length}
          simple={cursorNext ? true : false}
          hideOnSinglePage={true}
          showSizeChanger={true}
          onLoad={onLoad}
          current={currentPage}
          showSizeChangerOnSimple={cursorNext ? true : false}
          onPageChange={(page, pageSize) => {
            pageChange(page, pageSize, props.users?.length);
          }}
          onPageSizeChange={
            cursorNext
              ? (currentPage, pageSize) => {
                  pageChange(currentPage, pageSize, props.users?.length);
                }
              : sizeChange
          }
        ></PaginationGeneric>
      </div>
    </>
  );
};
