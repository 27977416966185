import { Button, Col, Descriptions, Form, Input, List, Modal, notification, Row, Tag, Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { DeleteOutlined, LockOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Page, ResourceSelect } from '../components';
import useUsers from '../hooks/useUsers';
import RolesSelect from '../components/RolesSelect';
import generalContent from '../conf/generalContent.json';
import usePermissions from 'hooks/usePermissions';
import usePrevious from '../hooks/usePrevious';
import useAssignmentRules, { IAssignmentRuleItem } from '../hooks/useAssignmentRules';

const { Title, Text } = Typography;
export default () => {
  const { pathname } = useLocation();
  const { applicationId = '', userId = '' } = useParams();
  const edit = pathname.endsWith('/edit');
  const [user, setUser] = useState({ id: '', profile: { email: '', firstName: '', lastName: '' } });
  const [resource, setResource] = useState('');
  const [roleId, setRoleId] = useState('');
  const [allowedRoleIds, setAllowedRoleIds] = useState<string[]>([]);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [deleteModalLoading, setDeleteModalLoading] = useState(false);
  const [deleteAssignment, setDeleteAssignment] = useState('');
  const [allAssignmentRules, setAllAssignmentRules] = useState<IAssignmentRuleItem[]>([]);
  const [formInstance] = Form.useForm();
  const { data, error, get, getAssignments, assignments, loadingAssignment, addAssignment, removeAssignment } =
    useUsers();
  const application = JSON.parse(localStorage.getItem('application') || '{}');
  const { checkPermissions } = usePermissions();
  const { resource: resourceTooltip, role: roleTooltip } = generalContent.general.edit;
  const previousApp = usePrevious(applicationId);
  const navigate = useNavigate();
  const { getAllAssignmentRules } = useAssignmentRules();

  const loadUser = () => {
    get(applicationId, userId);
    getAssignments(applicationId, userId);
  };

  const openNotificationWithIcon = (error: { message: string }) => {
    setVisibleDeleteModal(false);
    setDeleteModalLoading(false);
    notification.error({
      message: 'Action error!',
      description: error.message,
    });
  };

  useEffect(() => {
    if (!data) {
      loadUser();
      getAllAssignmentRules(applicationId).then((assignmentRules: IAssignmentRuleItem[]) =>
        setAllAssignmentRules(assignmentRules),
      );
    }
    if (data && data.items.length) {
      setUser(data.items[0]);
    }
  }, [data]);

  const addAssignmentToUser = (values: any) => {
    const { result: assignmentCode } = values;
    addAssignment(applicationId, userId, assignmentCode).then(() => {
      setAllowedRoleIds([]);
      setResource('');
      setRoleId('');
      formInstance.resetFields(['result']);
      formInstance.setFieldValue('resource', null);
      formInstance.setFieldValue('role', null);
    });
  };

  useEffect(() => {
    if (previousApp) {
      let url = `/${applicationId}/users/${userId}`;
      if (edit) url += `/edit`;
      navigate(url);
      loadUser();
    }
  }, [applicationId]);

  useEffect(() => {
    if (error && error?.data && error?.data?.error) {
      let message = error?.data?.error;
      if (message.startsWith('".body.')) {
        const fieldDelimitation = message.indexOf('"', 7);
        const name = message.substring(7, fieldDelimitation);
        message = message.substring(fieldDelimitation + 1).trim();
        message = message.charAt(0).toUpperCase() + message.slice(1);
        formInstance.setFields([{ name, errors: [message] }]);
        setVisibleDeleteModal(false);
        setVisibleDeleteModal(false);
      } else {
        openNotificationWithIcon({ message });
      }
    }
  }, [error]);

  const onValuesChange = function (_prev: any, values: any) {
    const { role, resource } = values;
    const assignmentCode = resource ? `${applicationId}.${resource}.${role}` : `${applicationId}.${role}`;
    if (role) {
      formInstance.setFieldValue('result', assignmentCode);
    }
  };

  const handleRemoveAssignment = function () {
    setDeleteModalLoading(true);
    removeAssignment(applicationId, userId, deleteAssignment).then(() => {
      setVisibleDeleteModal(false);
      setDeleteModalLoading(false);
    });
  };

  const deleteAssignmentModal = (assignment: string) => {
    setVisibleDeleteModal(true);
    setDeleteAssignment(assignment);
  };

  function renderRolesOptions(options: { label: string; value: string }[]) {
    const assignmentsCodes = (assignments || []).map((assignment: any) => assignment.code);
    const allAssignmentRuleCodes = (allAssignmentRules || [])
      .filter(({ lenient = false }) => !lenient)
      .map((assignmentRule: any) => assignmentRule.code);
    if (!assignmentsCodes) return options;
    return options.map(({ value, label }) => {
      const assignmentCode = resource ? `${applicationId}.${resource}.${value}` : `${applicationId}.${value}`;
      const disabled = [...assignmentsCodes, ...allAssignmentRuleCodes].indexOf(assignmentCode) >= 0;
      const extra = disabled
        ? allAssignmentRuleCodes.indexOf(assignmentCode) >= 0
          ? ' (Non lenient rule)'
          : ' (Already Added)'
        : '';

      return {
        value,
        label,
        disabled,
        extra,
      };
    });
  }

  const linkDocs = (
    <a href={generalContent.general.linkDocumentation} target='_blank' rel='noreferrer'>
      Learn more.
    </a>
  );

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const colStyle = {
    xs: 24,
    md: 12,
    xl: 10,
    xxl: 8,
  };

  const buttons = checkPermissions(applicationId, 'assignments:update') && (
    <Button type='primary'>
      <Link to={'edit'}>Edit</Link>
    </Button>
  );

  const onResourceChange = (resource: string, allowedRoleIds: string[] = []) => {
    setResource(resource);
    setAllowedRoleIds(allowedRoleIds);
  };

  const onRoleChange = (_value: string, roleId: string) => setRoleId(roleId);

  function getAssignmentRuleLink(assignment: any) {
    const assignmentRule: any = allAssignmentRules.find(({ code }) => code === assignment.code);
    if (!assignmentRule) return;
    return (
      <label>
        Managed by rule
        <Link to={`/${applicationId}/dynamic-assignments/${assignment.id}`}>
          <Tag color={`green`} style={{ margin: '5px' }} key={`${assignmentRule.id}-tags}`}>
            {assignmentRule.name || assignmentRule.code || ''}
          </Tag>
        </Link>
      </label>
    );
  }

  return (
    <Page title={edit ? 'Edit user' : 'User'} extra={!edit && buttons}>
      <Row style={{ marginBottom: '30px' }}>
        <Descriptions size='small' column={1}>
          <Descriptions.Item label={<Text strong>Application</Text>}>{application?.label || ''}</Descriptions.Item>
          <Descriptions.Item label={<Text strong>Name</Text>}>
            {`${user?.profile?.firstName} ${user?.profile?.lastName}` || ''}
          </Descriptions.Item>
          <Descriptions.Item label={<Text strong>Email</Text>}>{user?.profile?.email || ''}</Descriptions.Item>
        </Descriptions>
      </Row>
      <Row gutter={[16, 16]} style={{ paddingBottom: '24px' }}>
        {checkPermissions(applicationId, 'assignments:create') && (
          <Col {...colStyle} hidden={!edit}>
            <Form
              {...formItemLayout}
              form={formInstance}
              onValuesChange={onValuesChange}
              onFinish={addAssignmentToUser}
              initialValues={{ ...user }}
            >
              <Title level={5}>Add Role</Title>
              <Form.Item
                label='Resource'
                name='resource'
                tooltip={{
                  title: (
                    <>
                      {resourceTooltip} {linkDocs}
                    </>
                  ),
                  overlayInnerStyle: { textAlign: 'center' },
                }}
                shouldUpdate
              >
                <ResourceSelect
                  onChange={onResourceChange}
                  applicationId={applicationId}
                  valueIndex={'code'}
                  selectedRole={roleId}
                />
              </Form.Item>
              <Form.Item
                name='role'
                label='Roles'
                rules={[{ required: true }]}
                tooltip={{
                  title: (
                    <>
                      {roleTooltip} {linkDocs}
                    </>
                  ),
                  overlayInnerStyle: { textAlign: 'center' },
                }}
                shouldUpdate
              >
                <RolesSelect
                  allowedRoleIds={allowedRoleIds}
                  loading={loadingAssignment}
                  valueIndex={'code'}
                  applicationId={applicationId}
                  onChange={onRoleChange}
                  renderOptions={renderRolesOptions}
                />
              </Form.Item>
              <Form.Item shouldUpdate style={{ float: 'right' }}>
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={loadingAssignment}
                  disabled={loadingAssignment}
                  hidden={!edit}
                >
                  Add
                </Button>
              </Form.Item>
              <Form.Item name='result'>
                <Input hidden />
              </Form.Item>
            </Form>
          </Col>
        )}
        <Col {...colStyle}>
          <Title level={5}>
            Assignments{' '}
            <Tooltip
              title={
                <>
                  Assignments is where roles, resource, and an application all get joined together to make an assignment
                  code. These codes are then made available within a token when a user signs in. {linkDocs}
                </>
              }
              overlayInnerStyle={{ textAlign: 'center' }}
            >
              <QuestionCircleOutlined
                style={{ fontSize: '14px', color: 'rgba(0,0,0,.45)', cursor: 'help', marginRight: '2px' }}
              />
            </Tooltip>
            :
          </Title>
          <List loading={loadingAssignment} bordered style={{ width: '100%', minHeight: '53px' }}>
            {(assignments || []).length
              ? assignments.map((assignment: any, index: number) => (
                  <List.Item
                    key={`${assignment.code}-${index}`}
                    extra={
                      edit &&
                      checkPermissions(applicationId, 'assignments:delete') &&
                      (assignment.dynamic === false ? (
                        <DeleteOutlined
                          onClick={() => deleteAssignmentModal(assignment.code)}
                          style={{ paddingRight: '20px' }}
                        />
                      ) : (
                        <Tooltip title={"This is an Dynamic Assignment and can't be removed from here"}>
                          <label style={{ paddingRight: '20px', color: 'gray' }}>
                            <LockOutlined style={{ cursor: 'help' }} alt={'Dynamic Assignment'} />
                          </label>
                        </Tooltip>
                      ))
                    }
                  >
                    <List.Item.Meta
                      title={assignment.code}
                      description={assignment.dynamic === true ? getAssignmentRuleLink(assignment) : 'Manually'}
                    />
                  </List.Item>
                ))
              : !loadingAssignment && <List.Item>No Assignment</List.Item>}
          </List>
          <Modal
            title='Alert'
            visible={visibleDeleteModal}
            onOk={handleRemoveAssignment}
            okButtonProps={{ danger: true, disabled: deleteModalLoading }}
            okText={deleteModalLoading ? 'Removing' : 'Remove'}
            cancelButtonProps={{ disabled: deleteModalLoading }}
            confirmLoading={deleteModalLoading}
            onCancel={() => setVisibleDeleteModal(false)}
          >
            <p>
              Do you want to remove the assignment<Text code>{deleteAssignment}</Text>?
            </p>
          </Modal>
        </Col>
      </Row>
    </Page>
  );
};
