import { DeleteOutlined, EditOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { Button, Modal, notification, Result, Space, Typography } from 'antd';
import useLocalStorage from 'hooks/useLocalStorage';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Page } from '../components';
import generalContent from '../conf/generalContent.json';
import usePermissions from 'hooks/usePermissions';
import useAssignmentRules, { IAssignmentRuleItem } from 'hooks/useAssignmentRules';
import AssignmentRulesTable from '../components/AssignmentRulesTable';

const { Text } = Typography;

export default () => {
  const { applicationId = '' } = useParams<{ applicationId: string }>();
  const [assignmentRules, setAssignmentRules] = useState<IAssignmentRuleItem[]>([]);
  const [total, setTotal] = useState(0);
  const { data, loading, error, getAllWithLimit } = useAssignmentRules();
  const { checkPermissions } = usePermissions();
  const [lsPageSize] = useLocalStorage<number>('am-page-size', 10);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [deleteModalLoading, setDeleteModalLoading] = useState(false);
  const [assignmentRuleToDelete, setAssignmentRuleToDelete] = useState<IAssignmentRuleItem>({
    resourceId: '',
    applicationId: '',
    dynamic: true,
    code: '',
    description: '',
    id: '',
    lenient: false,
    name: '',
    oktaGroupId: '',
    roleId: '',
    type: 0,
  });
  const { removeAssignmentRule } = useAssignmentRules();

  const loadAssignmentRules = () => {
    setAssignmentRules([]);
    getAllWithLimit(applicationId || '', lsPageSize, 0, 'value asc');
  };

  useEffect(() => {
    loadAssignmentRules();
  }, [applicationId]);

  useEffect(() => {
    const items = data?.items;
    setAssignmentRules(items || []);
    setTotal(data?.total || 0);
  }, [data, data?.items]);

  const onPageChange = (val: number, pageSize: number, sortPage: string) => {
    getAllWithLimit(applicationId || '', pageSize, (val - 1) * pageSize, sortPage);
  };

  const onSortChange = (sort: string) => {
    getAllWithLimit(applicationId || '', lsPageSize, 0, sort);
  };

  const buttons = checkPermissions(applicationId, 'assignmentRule:create') && (
    <Button type='primary'>
      <Link to={'create'}>Add Rule</Link>
    </Button>
  );

  const deleteAssignmentRule = (assignmentRule: IAssignmentRuleItem) => {
    setAssignmentRuleToDelete(assignmentRule);
    setVisibleDeleteModal(true);
  };

  const editCta = (assignmentRule: IAssignmentRuleItem) => {
    return (
      checkPermissions(applicationId, 'assignmentRule:update') && (
        <Link to={`${assignmentRule.id}/edit`}>
          <EditOutlined style={{ fontSize: '18px' }} title='edit' />
        </Link>
      )
    );
  };

  const deleteCta = (assignmentRule: IAssignmentRuleItem) => {
    return (
      checkPermissions(applicationId, 'assignmentRule:delete') && (
        <DeleteOutlined
          style={{ fontSize: '18px', color: 'red' }}
          title='delete'
          onClick={() => deleteAssignmentRule(assignmentRule)}
        />
      )
    );
  };

  const renderActions = (assignmentRule: IAssignmentRuleItem) => (
    <Space size='middle'>
      {editCta(assignmentRule) || deleteCta(assignmentRule) ? (
        <>
          {editCta(assignmentRule)} {deleteCta(assignmentRule)}
        </>
      ) : (
        '-'
      )}
    </Space>
  );

  const handleDeleteOk = () => {
    setDeleteModalLoading(true);
    removeAssignmentRule(applicationId, assignmentRuleToDelete?.id || '', (resp: any) => {
      if (resp?.name !== 'AxiosError') {
        notification.success({
          message: 'Success',
          description: `Resource ${assignmentRuleToDelete?.name} was successfully deleted`,
        });
        setVisibleDeleteModal(false);
        setDeleteModalLoading(false);
        loadAssignmentRules();
      }
    });
  };

  return (
    <Page
      title={'Dynamic Assignment'}
      titleIcon={<ThunderboltOutlined />}
      description={generalContent.pages.dynamicAssignments.pageDescription}
      className='AssignmentRulesPage'
      extra={error ? undefined : buttons}
    >
      {error ? (
        <Result title={error?.data?.error || 'Something is wrong. Please try again in a few minutes.'} />
      ) : (
        <>
          <AssignmentRulesTable
            data={assignmentRules}
            total={total}
            loading={loading}
            onPageChange={onPageChange}
            onSortChange={onSortChange}
            renderActions={renderActions}
          />
          <Modal
            title='Alert'
            visible={visibleDeleteModal}
            onOk={handleDeleteOk}
            centered
            okButtonProps={{ danger: true, disabled: deleteModalLoading }}
            okText={deleteModalLoading ? 'Removing' : 'Remove'}
            cancelButtonProps={{ disabled: deleteModalLoading }}
            confirmLoading={deleteModalLoading}
            onCancel={() => setVisibleDeleteModal(false)}
          >
            <p>
              Do you want to remove the assignment rule{' '}
              <Text code>{assignmentRuleToDelete.name || assignmentRuleToDelete?.code || ''}</Text>?
            </p>
          </Modal>
        </>
      )}
    </Page>
  );
};
