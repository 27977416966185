import useAxios from './useAxios';

export interface IApplication {
  code: string;
  created?: string;
  defaultRoleAssignments?: any[];
  description?: string;
  id: string;
  label: string;
  name: string;
  oktaCompatibilityMode?: boolean;
  oktaGroupName: string;
  selfServiceAssignments?: any[];
  settings?: any;
  updated?: string;
  value: string;
  oktaGroupId?: string;
  oktaClientId?: string;
}

export default (): {
  data: any;
  error: any;
  loading: boolean;
  getAll: () => void;
  getSelectedApplication: () => any;
  setApplication: (application: any) => void;
  updateAppLocalStorage: (target: any, value: any) => void;
  updateNameDescription: (application: string, name: string, description: string, callback: any) => void;
  updateSettings: (application: string, settings: any, callback?: any) => void;
  updateDefaultRoleAssignments: (application: string, defaultRoleAssignments: any[], callback?: any) => void;
  updateSelfServiceAssignments: (application: string, selfServiceAssignments: any[], callback?: any) => void;
  updateApplication: (
    applicationId: string,
    name?: string,
    description?: string,
    defaultRoleAssignments?: any[],
    selfServiceAssignments?: string[],
  ) => void;
} => {
  const { data, loading, error, request, requestAsync } = useAxios();

  const getSelectedApplication = () => {
    const app = localStorage.getItem('application');
    const appFallback = {
      code: '',
      defaultRoleAssignments: [],
      id: '',
      name: '',
      oktaCompatibilityMode: false,
      oktaGroupName: '',
      value: '',
    };
    return app ? JSON.parse(app) : appFallback;
  };

  const setApplication = (application: any) => {
    console.log(application);
    localStorage.setItem('application', JSON.stringify(application));
  };

  const updateAppLocalStorage = (target: any, value: any) => {
    const app = getSelectedApplication();
    if (app.id) {
      app[target] = value;
      localStorage.setItem('application', JSON.stringify(app));
    }
  };

  const updateApplication = async (
    applicationId: string,
    name?: string,
    description?: string,
    defaultRoleAssignments?: any[],
    selfServiceAssignments?: string[],
    callback?: any,
  ) => {
    const updatedApp: any = {};
    if (name) updatedApp['name'] = name;
    if (description) updatedApp['description'] = description;
    if (defaultRoleAssignments) updatedApp['defaultRoleAssignments'] = defaultRoleAssignments;
    if (selfServiceAssignments) updatedApp['selfServiceAssignments'] = selfServiceAssignments;

    return await requestAsync(`/applications/${applicationId}`, 'put', updatedApp, callback);
  };

  const updateNameDescription = async (applicationId: string, name: string, description: string, callback: any) => {
    return await requestAsync(`/applications/${applicationId}`, 'put', { name, description }, callback);
  };

  const updateSettings = async (applicationId: string, settings: any, callback: any) => {
    return await requestAsync(`/applications/${applicationId}`, 'put', { settings }, callback);
  };

  const updateDefaultRoleAssignments = async (applicationId: string, defaultRoleAssignments: any[], callback: any) => {
    return await requestAsync(`/applications/${applicationId}`, 'put', { defaultRoleAssignments }, callback);
  };

  const updateSelfServiceAssignments = async (applicationId: string, selfServiceAssignments: any[], callback: any) => {
    return await requestAsync(`/applications/${applicationId}`, 'put', { selfServiceAssignments }, callback);
  };

  return {
    data,
    error,
    loading,
    getAll: () => request(`/applications`),
    getSelectedApplication,
    setApplication,
    updateApplication,
    updateDefaultRoleAssignments,
    updateNameDescription,
    updateSelfServiceAssignments,
    updateSettings,
    updateAppLocalStorage,
  };
};
