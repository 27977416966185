import { Button, Col, Form, Input, Modal, Row, Spin, Typography, notification } from 'antd';
import { useEffect, useState } from 'react';
import TextArea from 'antd/es/input/TextArea';
import { Page } from '../components';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import usePrevious from '../hooks/usePrevious';
import { NotFoundPage } from './index';
import useRoles from '../hooks/useRoles';
import generalContent from '../conf/generalContent.json';
import usePermissions from 'hooks/usePermissions';

const { Text } = Typography;
export default () => {
  const { applicationId = '', roleId = '' } = useParams();
  const [role, setRole] = useState({});
  const [fromAppId, setFromAppId] = useState(false);
  const [tempRole, setTempRole] = useState({}); // When editing, keep the old form values
  const [visibleSaveRoleModal, setVisibleSaveRoleModal] = useState(false);
  const [saveRoleModalLoading, setSaveRoleModalLoading] = useState(false);
  const [roleName, setRoleName] = useState('');
  const [form] = Form.useForm();
  const { data, loading, error, updateRole, createRole, getRole } = useRoles();
  const { pathname } = useLocation();
  const previousApp = usePrevious(applicationId);
  const navigate = useNavigate();
  const edit = pathname.endsWith('/edit');
  const create = pathname.endsWith('/create');
  const { checkPermissions } = usePermissions();
  const { code: codeTooltip, description: descriptionTooltip, name: nameTooltip } = generalContent.general.edit;

  useEffect(() => {
    return () => {
      form.setFieldsValue(tempRole);
    };
  }, [navigate]);

  useEffect(() => {
    // Redirect to roles page the user are in the edit page and change the application
    if (previousApp) navigate(`/${applicationId}/roles`);

    // Request role by id
    if (applicationId && roleId) getRole(applicationId, roleId);
  }, [applicationId]);

  const openNotificationWithIcon = (error: { message: string }) => {
    setVisibleSaveRoleModal(false);
    setSaveRoleModalLoading(false);
    notification.error({
      message: 'Action error!',
      description: error.message,
    });
  };
  useEffect(() => {
    if (role) {
      form.setFieldsValue(role);
      setTempRole(role);
    }
    setVisibleSaveRoleModal(false);
    setSaveRoleModalLoading(false);
  }, [role]);

  useEffect(() => {
    data && setRole(data);
  }, [data]);

  useEffect(() => {
    if (error && error?.data && error?.data?.error) {
      let message = error?.data?.error;
      if (message.startsWith('".body.')) {
        const fieldDelimitation = message.indexOf('"', 7);
        const name = message.substring(7, fieldDelimitation);
        message = message.substring(fieldDelimitation + 1).trim();
        message = message.charAt(0).toUpperCase() + message.slice(1);
        form.setFields([{ name, errors: [message] }]);
        setVisibleSaveRoleModal(false);
        setSaveRoleModalLoading(false);
      } else {
        openNotificationWithIcon({ message });
      }
    }
  }, [error]);

  const onFinish = function (values: any) {
    setRoleName(values.name);
    setVisibleSaveRoleModal(true);
  };

  const handleOk = function () {
    if (!form.getFieldValue('description')) form.setFieldValue('description', '');
    const role = form.getFieldsValue();
    setSaveRoleModalLoading(true);
    const success = (description: string) => {
      setVisibleSaveRoleModal(false);
      setSaveRoleModalLoading(false);
      notification.success({
        message: 'Success',
        description,
      });
    };

    // Navigation - edit/add
    navigate(`/${applicationId}/roles/${roleId}`, {
      replace: true,
      state: { role, tempRole: role },
    });
    setRole(role);
    setTempRole(role);
    form.setFieldsValue(role);

    if (!create && fromAppId) {
      setFromAppId(false);
      history.back();
    }

    if (create) {
      createRole(applicationId, role, () => {
        success(`Role was successful created!`);
        navigate(`/${applicationId}/roles`);
      });
    } else {
      updateRole(applicationId, roleId, role, () => success(`Resource was successful updated!`));
    }
  };

  const linkDocs = (
    <a href={generalContent.general.linkDocumentation} target='_blank' rel='noreferrer'>
      Learn more.
    </a>
  );

  const readOnly = !edit && !create;
  const disabled = readOnly || (!create && loading);
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const colStyle = {
    xs: 24,
    lg: 12,
    xl: 10,
    xxl: 8,
  };
  const fieldClassName = readOnly ? 'show-only-field' : '';
  return (
    <Page
      title={edit ? 'Edit role' : 'Role'}
      extra={
        readOnly &&
        !error &&
        checkPermissions(applicationId, 'roles:update') && (
          <Button key='edit-role' type='primary' onClick={() => setFromAppId(true)}>
            <Link to='edit'>Edit Role</Link>
          </Button>
        )
      }
    >
      {loading && !create && <Spin style={{ position: 'fixed', left: '50%', top: '50%' }} />}
      {error && error?.status === 404 && <NotFoundPage {...error.data} />}
      <Form
        {...formItemLayout}
        labelCol={{ span: 6 }}
        onFinish={onFinish}
        wrapperCol={{ span: 18 }}
        form={form}
        initialValues={{ ...role }}
      >
        <Row gutter={[24, 0]}>
          <Col {...colStyle}>
            <Form.Item
              name='name'
              label='Name'
              tooltip={{
                title: (
                  <>
                    {nameTooltip} {linkDocs}
                  </>
                ),
                overlayInnerStyle: { textAlign: 'center' },
              }}
              rules={[
                {
                  required: !readOnly,
                  pattern: new RegExp(/^(?!\s)(?![\w_ -]*\s\s)([a-zA-Z0-9 _-]{2,50})$/g),
                  message:
                    'Name required. It must be 2-50 characters long. No double or finishing with whitespace. No special characters but "-" or "_".',
                },
              ]}
              validateTrigger={['onChange', 'onBlur']}
              shouldUpdate
            >
              <Input disabled={disabled} className={fieldClassName} />
            </Form.Item>
            <Form.Item
              name='description'
              label='Description'
              tooltip={{
                title: (
                  <>
                    {descriptionTooltip} <br /> {linkDocs}
                  </>
                ),
                overlayInnerStyle: { textAlign: 'center' },
              }}
              shouldUpdate
            >
              <TextArea disabled={disabled} className={fieldClassName} />
            </Form.Item>
          </Col>
          <Col {...colStyle}>
            <Form.Item
              name='code'
              label='Code'
              tooltip={{
                title: (
                  <>
                    {codeTooltip} {linkDocs}
                  </>
                ),
                overlayInnerStyle: { textAlign: 'center' },
              }}
              className='field-disabled'
              rules={[
                {
                  required: create,
                  pattern: new RegExp(/^[a-zA-Z0-9-_]{2,50}$/),
                  message:
                    'Field code required. It must be 2-50 characters long, with no whitespace or special character but "-" or "_".',
                },
              ]}
              validateTrigger={['onChange', 'onBlur']}
              shouldUpdate
            >
              <Input disabled={!create} className={create ? '' : 'show-only-field'} />
            </Form.Item>
          </Col>
        </Row>
        <Row hidden={readOnly} gutter={[8, 0]} style={{ float: 'right' }}>
          <Col>
            <Form.Item shouldUpdate>
              <Button type='primary' disabled={saveRoleModalLoading} danger>
                <Link to={'..'}>Cancel</Link>
              </Button>
            </Form.Item>
          </Col>
          <Col span={2}>
            <Form.Item shouldUpdate>
              <Button type='primary' loading={saveRoleModalLoading} disabled={saveRoleModalLoading} htmlType='submit'>
                {create ? 'Create' : 'Save'}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Modal
        title='Alert'
        visible={visibleSaveRoleModal}
        onOk={handleOk}
        okButtonProps={{ disabled: saveRoleModalLoading }}
        okText={saveRoleModalLoading ? 'Saving' : 'Save'}
        cancelButtonProps={{ disabled: saveRoleModalLoading }}
        confirmLoading={saveRoleModalLoading}
        onCancel={() => setVisibleSaveRoleModal(false)}
      >
        <p>
          Do you want to {create ? 'create' : 'save'} the role<Text code>{roleName}</Text>?
        </p>
      </Modal>
    </Page>
  );
};
