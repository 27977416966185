import conf from '../conf';
import { FileTextOutlined } from '@ant-design/icons';
import generalContent from '../conf/generalContent.json';
import ImportSyncTable from 'components/importSyncTable';
import { Page } from '../components';
import useAxios from 'hooks/useAxios';
import { useEffect } from 'react';
import { Col, Result, Row, Spin } from 'antd';

type Sync = {
  _id: string;
  created: string;
  elapsedTime: number;
  fileName: string;
  isCancelled: boolean;
  messages: any[];
  total: number;
};

export default () => {
  const { data, error, loading, request } = useAxios();

  const syncList = data as Sync[];

  const errorMessage =
    error &&
    ((typeof error.data?.message === 'string' && error.data.message) ||
      (typeof error.data === 'string' && error.data) ||
      'Unknown error');

  // Get the list of synchronizations
  useEffect(() => {
    request(`${conf.import.baseURL}/users/sync?sortOrder=desc`);
  }, [conf.import.baseURL]);

  // TODO: extract to a functional component
  const Loading = (
    <Row gutter={[24, 0]} style={{ height: '50vh', position: 'relative' }}>
      <Col style={{ width: '100%' }}>
        <Spin style={{ position: 'absolute', left: '50%', top: '40%' }} />{' '}
      </Col>
    </Row>
  );

  return (
    <Page
      description={generalContent.pages.importList.pageDescription}
      title={generalContent.pages.importList.title}
      titleIcon={<FileTextOutlined />}
    >
      {error && <Result title={errorMessage} />}

      {loading && Loading}

      {syncList && !error && <ImportSyncTable syncList={syncList} loading={loading} />}
    </Page>
  );
};
