import UserTable from '../components/UserTable';
import { Page } from '../components';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useUsers, { IUser } from '../hooks/useUsers';
import useQuery from '../hooks/useQuery';
import type { CustomTagProps } from 'rc-select/lib/BaseSelect';
import useAssignments from '../hooks/useAssignments';
import { Modal, Select, Space, Spin, Tag } from 'antd';
import { SearchOutlined, UserOutlined } from '@ant-design/icons';
import generalContent from '../conf/generalContent.json';

const { Option, OptGroup } = Select;

interface IOptionValue {
  key: string;
  label: string;
  value: string;
}

const Components = () => {
  const { applicationId = '' } = useParams<{ applicationId: string }>();
  const [users, setUsers] = useState<IUser[]>([]);
  const [cursorNext, setCursorNext] = useState('');
  const {
    // data: usersList,
    getAllWithPagination,
    loading: usersLoading,
    searchUserAndAssignments,
    searchAssignments,
  } = useUsers();
  const { getAll: getAllAssignment, assignments: assignmentsArr, loading: loadingAssignments } = useAssignments();
  const query = useQuery();
  const [assignment, user, search] = [query.get('assignment'), query.get('user'), query.get('search')];
  const navigate = useNavigate();
  const [searchInputValue, setSearchInputText] = useState<IOptionValue[]>([]);
  const [userInput, setUserInput] = useState<string>('');
  const [options, setOptions] = useState<IOptionValue[]>([]);
  const [pageSize, setPageSize] = useState<number>(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [assignments, setAssignments] = useState<string[]>([]);
  const [total, setTotal] = useState<any>(0);

  const resetParams = function () {
    setCursorNext('');
    setAssignments([]);
    setUsers([]);
    setLoading(false);
  };

  useEffect(() => {
    setAssignments(assignmentsArr);
  }, [assignmentsArr]);

  const getPaginationParameters = () => {
    const offset = 0;
    const limit = pageSize * 2 || 100;
    return { offset, limit, after: cursorNext };
  };

  const createModalError = (error: any) => {
    if (error?.response?.data?.message) {
      setModalMessage(error?.response?.data?.message || 'An error occurred while processing the request');
      setModalVisible(true);
    }
  };

  const loadUsers = () => {
    if (loading) return;
    if ((assignments.length > 0 && applicationId !== 'undefined') || applicationId !== undefined) {
      const filteredAssignments = assignments.filter((assignment: string) => assignment.indexOf(search || '') >= 0);
      if (search) {
        setTotal(0);
        searchUserAndAssignments({
          applicationId,
          search,
          assignments: filteredAssignments,
          next: cursorNext,
        })
          .then((userResponse: any) => {
            const { data } = userResponse;
            const items = [...(data?.cursor.next || data?.cursor.self ? users : []), ...data.items].filter(
              (value: any, index, self) => index === self.findIndex((indexValue) => indexValue.id === value.id),
            );
            setLoading(false);
            setUsers(items);
            setCursorNext(data?.cursor.next || data?.cursor.self);
          })
          .catch(createModalError);
      }

      if (assignment) {
        setLoading(true);
        searchAssignments({
          applicationId,
          assignments: [assignment],
          ...getPaginationParameters(),
          offset: (users || []).length,
        })
          .then((userResponse: any) => {
            const { data } = userResponse;
            const items = [...(data?.cursor.next || data?.cursor.self ? users : []), ...data.items].filter(
              (value: any, index, self) => index === self.findIndex((indexValue) => indexValue.id === value.id),
            );
            setLoading(false);
            setUsers(items || []);
            setTotal(data?.cursor?.total);
          })
          .catch(createModalError);
      }

      if (assignment === null && user === null && search === null) {
        setTotal(0);
        setLoading(true);
        getAllWithPagination({
          applicationId,
          ...getPaginationParameters(),
        })
          .then((userResponse: any) => {
            const { data } = userResponse;
            const items = [...(data?.cursor.self ? users : []), ...data.items].filter(
              (value: any, index, self) => index === self.findIndex((indexValue) => indexValue.id === value.id),
            );
            setLoading(false);
            setUsers(items);
            setCursorNext(data?.cursor.next || '');
          })
          .catch(createModalError);
      }
    }
  };

  const onFinalPage = (_applicationId: string, _cursor: string) => {
    loadUsers();
  };

  useEffect(() => {
    setLoading(usersLoading);
  }, [usersLoading]);

  useEffect(() => {
    if (assignment || user || search) {
      setSearchInputText([
        {
          key: assignment ? `opt-assignment-${assignment}` : user ? `user: ${user}` : search ? `search: ${search}` : '',
          value: assignment || user || search || '',
          label: assignment || user || search || '',
        },
      ]);
    }
  }, [assignment, user, search, assignments.length > 0]);

  useEffect(() => {
    assignments.length === 0 && getAllAssignment(applicationId);
  }, [assignments]);

  useEffect(() => {
    resetParams();
  }, [applicationId]);

  const onSearch = (value: string) => {
    setSearchInputText([]);
    setUserInput(value || '');
    setOptions(
      value
        ? (assignments as any)
            .filter((assignment: string) => `${assignment}`.toLowerCase().indexOf(`${value}`.toLowerCase()) >= 0)
            .map((code: string) => ({ value: code, label: code }))
        : [],
    );
  };
  const onChange = (value: IOptionValue[], options: IOptionValue | IOptionValue[]) => {
    resetParams();
    setCursorNext('');
    let url = ``;
    if (options && Array.isArray(options) && options.length) {
      const option: IOptionValue = options.reduce((acc, opt) => {
        if (opt) acc = opt;
        return acc;
      });
      const [_, type] = option.key.split('-');
      if (type) {
        url = `?${type}=${encodeURIComponent(option.value)}`;
      }
    }
    console.log(`navigate`, url);
    setSearchInputText([...value]);
    navigate(url);
  };

  useEffect(() => {
    setCursorNext('');
    resetParams();
    loadUsers();
  }, [assignment, search]);

  const tagRender = (props: CustomTagProps) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      (label || value) && (
        <Tag onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
          {label}
        </Tag>
      )
    );
  };

  const onPageChange = (_pageNumberValue: number, pageSizeValue: number) => {
    console.log(`onPageChange`, { _pageNumberValue, pageSizeValue });
    setPageSize(pageSizeValue);
  };

  const onPaginationLoad = (settings: { pageSizeInner: number; currentPage: number }) => {
    const { pageSizeInner } = settings;
    setPageSize(pageSizeInner || 10);
  };

  useEffect(() => {
    assignments.length > 0 && loadUsers();
  }, [assignments.length > 0, pageSize]);

  const buttons = [
    <Space key='search-box'>
      <Select
        className='search-box_select'
        mode='multiple'
        key='search-values'
        placeholder='Search for a user'
        labelInValue
        value={searchInputValue}
        filterOption={false}
        onSearch={onSearch}
        notFoundContent={loadingAssignments ? <Spin size='small' /> : null}
        onChange={onChange}
        style={{ minWidth: 200 }}
        suffixIcon={<SearchOutlined />}
        tagRender={tagRender}
        allowClear={true}
      >
        {userInput && (
          <OptGroup label={`Search:`}>
            <Option key={`opt-search-${userInput}`} value={userInput} lable={userInput}>
              {userInput}
            </Option>
          </OptGroup>
        )}
        {options.length && (
          <OptGroup key={`opt-group-assignments`} label={`Assignments:`}>
            {(options || []).map((option: IOptionValue) => (
              <Option key={`opt-assignment-${option.value}`} value={option.value}>
                {option.label}
              </Option>
            ))}
          </OptGroup>
        )}
      </Select>
      <SearchOutlined style={{ color: '#a7a7a7' }} className='search-box_icon' />
    </Space>,
  ];

  return (
    <Page
      title='Users'
      extra={buttons}
      titleIcon={<UserOutlined />}
      description={generalContent.pages.users.pageDescription}
    >
      <Modal
        title='Alert'
        visible={modalVisible}
        onOk={() => setModalVisible(false)}
        cancelButtonProps={{ disabled: true }}
        onCancel={() => setModalVisible(false)}
      >
        <p>{modalMessage}</p>
      </Modal>
      <UserTable
        users={users}
        total={total}
        loading={loading}
        cursorNext={cursorNext}
        hideAssignments={false}
        onFinalPage={onFinalPage}
        onPageChange={onPageChange}
        onPaginationLoad={onPaginationLoad}
      />
    </Page>
  );
};

export default Components;
