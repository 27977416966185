import useAxios from './useAxios';

export interface IOktaGroup {
  id: string;
  name: string;
}

export default (): {
  data: any;
  error: any;
  loading: boolean;
  getAll: (applicationId: string) => void;
} => {
  const { data, loading, error, requestAsync } = useAxios();

  const getAll = (applicationId: string): Promise<IOktaGroup[]> => {
    return requestAsync(`/applications/${applicationId}/okta-groups`, 'get');
  };

  return {
    data,
    error,
    loading,
    getAll,
  };
};
