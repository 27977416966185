import { Select } from 'antd';
import useResources, { IResource } from '../hooks/useResources';
import { useEffect, useState } from 'react';

export default (props: {
  value?: string;
  applicationId: string;
  valueIndex?: keyof IResource;
  readOnly?: boolean;
  selectedRole?: string;
  className?: string;
  loading?: boolean;
  onChange?: (value: string, roleIds: string[]) => void;
  currentResource?: (resource: IResource | null) => void;
}) => {
  const { readOnly = false, className = '', valueIndex = 'id', applicationId, onChange, selectedRole, value } = props;
  const [resources, setResources] = useState<any[]>([]);
  const [resourcesObjects, setResourcesObjects] = useState<IResource[]>([]);
  const [selectedResource, setSelectedResource] = useState<IResource>();
  const { data, loadingRecursive, getCompleteData } = useResources();

  useEffect(() => {
    setResources([]);
    applicationId && getCompleteData(applicationId);
  }, [applicationId]);

  useEffect(() => {
    if (value && resources.length) {
      setSelectedResource(resourcesObjects.find((resource: IResource) => resource[valueIndex || 'id'] === value));
    }
  }, [resources, value]);

  useEffect(() => {
    if (props?.currentResource && selectedResource) {
      props?.currentResource(value ? selectedResource : null);
    }
  }, [selectedResource, value]);

  useEffect(() => {
    // Returns the resources whose allowed roles aren't set, or that include the role that has been selected by the user
    const items: IResource[] = data?.items || [];
    setResourcesObjects(items);
    setResources(
      items
        .filter(
          ({ allowedRoleIds }) => !selectedRole || !allowedRoleIds.length || allowedRoleIds.includes(selectedRole),
        )
        .map((resource) => ({
          label: resource.name,
          value: resource[valueIndex],
          allowedRoleIds: resource.allowedRoleIds,
        })),
    );
  }, [data, loadingRecursive, selectedRole]);

  // When a resource is selected, onChange is called with the allowed roles related to the selected resource.
  return (
    <Select
      value={value}
      onChange={(v) => onChange?.(v, resources.find((resource) => resource.value === v)?.allowedRoleIds)}
      showSearch
      loading={loadingRecursive}
      disabled={readOnly || loadingRecursive}
      placeholder={readOnly ? '(No parent)' : 'Search and Select a Resource'}
      optionFilterProp='children'
      className={className}
      options={resources}
      allowClear
      filterOption={(inputValue, option) => {
        const label = `${option?.label || ''}`;
        const labelUp: string = label?.toUpperCase() || '';
        return labelUp?.indexOf(inputValue.toUpperCase()) !== -1;
      }}
    />
  );
};
