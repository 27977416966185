import { Table, Space, Typography, Tag } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { DeleteOutlined, EditOutlined, LockOutlined } from '@ant-design/icons';
import { IUser } from '../hooks/useUsers';
import { AssignmentsTagList } from './index';
import usePermissions from 'hooks/usePermissions';
import PaginationGeneric from './PaginationGeneric';

const { Column } = Table;
const { Text } = Typography;

export default (props: {
  users: IUser[];
  total: number;
  loading: boolean;
  hideAssignments?: boolean;
  userType: string;
  onDelete: (userId: IUser) => void;
  onPageChange?: (val: number, pageSize: number) => void;
  onSizeChange?: (val: number, pageSize: number) => void;
  onPaginationLoad?: (settings: { pageSizeInner: number }) => void;
}) => {
  const { applicationId = '' } = useParams<{ applicationId: string }>();
  const { onDelete, onPageChange, onPaginationLoad, onSizeChange } = props;
  const { checkPermissions } = usePermissions();

  const editCta = (user: any) => {
    return (
      <Link to={`/${applicationId}/users/${user.id}/edit`}>
        <EditOutlined style={{ fontSize: '18px' }} />
      </Link>
    );
  };

  const deleteCta = (user: any) => {
    return (
      checkPermissions(applicationId, 'assignments:delete') && (
        <DeleteOutlined style={{ fontSize: '18px', color: 'red' }} onClick={() => onDelete(user)} />
      )
    );
  };

  const pageChange = (currentPage: number, pageSize: number) => {
    if (typeof onPageChange === 'function') onPageChange(currentPage, pageSize);
  };

  const onLoad = (settings: any) => {
    if (typeof onPaginationLoad === 'function') onPaginationLoad(settings);
  };

  const sizeChange = (currentPage: number, pageSize: number) => {
    if (typeof onSizeChange === 'function') onSizeChange(currentPage, pageSize);
  };

  const renderActions = (user: any) => (
    <Space hidden={!user.valid} size='middle'>
      {editCta(user) || deleteCta(user) ? (
        user.meta?.dynamic === true ? (
          <LockOutlined disabled />
        ) : (
          <>
            {editCta(user)}
            {deleteCta(user)}
          </>
        )
      ) : (
        '-'
      )}
    </Space>
  );

  return (
    <>
      <Table
        dataSource={(props.users || []).map((user: any) => ({ ...user, key: `user-${user?.id}` }))}
        pagination={false}
        loading={props.loading}
      >
        <Column
          title='Name'
          render={({ id, profile, label = '', valid = true }) =>
            valid ? (
              <Link to={`/${applicationId}/${props.userType === 'users' ? 'users' : 'okta-clients'}/${id}`}>{`${
                profile?.firstName || label
              } ${profile?.lastName || ''}`}</Link>
            ) : (
              <Text italic disabled>
                {id}(Not exist in Okta)
              </Text>
            )
          }
        />
        <Column
          title='Email'
          render={({ id, profile, valid = true }) =>
            valid && <Link to={`/${applicationId}/${props.userType}/${id}`}>{`${profile?.login || ''} `}</Link>
          }
        />
        <Column
          title='Managed by'
          align={'center'}
          render={({ valid = true, meta }) =>
            valid && (meta || { dynamic: false }).dynamic === true ? (
              <label>
                By Rule
                <Link to={`/${applicationId}/dynamic-assignments/${meta?.rule?.id}`}>
                  <Tag color={`green`} style={{ margin: '5px' }} key={`${meta?.rule?.id}-tags}`}>
                    {meta?.rule?.name || meta?.rule?.code || ''}
                  </Tag>
                </Link>
              </label>
            ) : (
              valid && (
                <Text italic disabled>
                  Manually
                </Text>
              )
            )
          }
        />
        {!props?.hideAssignments && (
          <Column title='Assignments' render={(user) => <AssignmentsTagList user={user} />} />
        )}
        <Column title='Actions' key='action' align='center' render={renderActions} />
      </Table>
      <PaginationGeneric
        loading={props.loading}
        total={props.total}
        hideOnSinglePage={true}
        showSizeChanger={true}
        onLoad={onLoad}
        onPageSizeChange={sizeChange}
        onPageChange={(page, pageSize) => {
          pageChange(page, pageSize);
        }}
      ></PaginationGeneric>
    </>
  );
};
