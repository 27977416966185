import { Page, ApplicationsTable } from '../components';
import { useNavigate, useParams } from 'react-router-dom';
import { CSSProperties, useEffect, useState } from 'react';
import useApplications from '../hooks/useApplications';
import { Avatar, Col, Collapse, Row, Spin, Badge, Descriptions } from 'antd';
import { AppstoreOutlined } from '@ant-design/icons';
import generalContent from '../conf/generalContent.json';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';

export default () => {
  const savedApplication = localStorage.getItem('application');
  const selectedOption = savedApplication ? JSON.parse(savedApplication) : null;
  const [application, setApplication] = useState(selectedOption || { label: '', value: '' });
  const { applicationId } = useParams();
  const navigate = useNavigate();
  const { Panel } = Collapse;
  const { Item } = Descriptions;
  const [applications, setApplications] = useState();
  const { data, getAll, loading } = useApplications();
  const screens = useBreakpoint();

  const changeApp = (app: string) => navigate(`/${app}`);

  useEffect(() => {
    !applicationId && selectedOption?.value && navigate(`/${application?.value}`);
  }, [applicationId, selectedOption]);

  useEffect(() => {
    data && data?.items
      ? setApplications(data.items.map((value: any) => ({ label: value.name, value: value.code, ...value })))
      : getAll();
  }, [data]);

  useEffect(() => {
    const selectedApp = localStorage.getItem('application');
    setApplication(selectedApp ? JSON.parse(selectedApp) : { label: '', value: '' });
  }, [navigate]);

  const cover = (application: { label: string; value: string }) => {
    return (
      <Avatar
        size={100}
        style={{ backgroundColor: '#214E7A', color: 'white', maxWidth: '80%' }}
        alt={application.label}
        shape='square'
      >
        {application.value.toUpperCase()}
      </Avatar>
    );
  };

  const cardStyles: CSSProperties = {
    padding: '0',
    borderRadius: '5px',
    background: '#214E7A',
    color: 'white',
    height: '160px',
    textAlign: 'center',
    width: '200px',
  };

  const applicationCard = (application: any) => {
    return (
      <div key='card' style={cardStyles}>
        {application.oktaCompatibilityMode ? (
          <Badge.Ribbon text={'Compatibility Mode'}>{cover(application)}</Badge.Ribbon>
        ) : (
          cover(application)
        )}
        <div>
          <span style={{ padding: '5px 5px 0' }}>{application.label}</span>
        </div>
      </div>
    );
  };

  const applicationDesc = (application: any) => {
    return (
      <Descriptions
        key='descApp'
        column={1}
        contentStyle={{}}
        layout={screens.md ? 'horizontal' : 'vertical'}
        size={'middle'}
        bordered
      >
        <Item key='Description' label='Description' labelStyle={{ width: '20%' }} contentStyle={{ width: '80%' }}>
          {application?.description?.length ? application.description : '-'}
        </Item>
        <Item key='OktaGroup' label='Okta Group' labelStyle={{ width: '20%' }} contentStyle={{ width: '80%' }}>
          {application?.oktaGroupName?.length ? application.oktaGroupName : '-'}
        </Item>
      </Descriptions>
    );
  };

  return (
    <Page
      title={'Applications'}
      titleIcon={<AppstoreOutlined />}
      description={generalContent.pages.applications.pageDescription}
      className='applicationsPage'
    >
      {loading ? (
        <Spin style={{ minHeight: '400px', position: 'relative', left: '50%', top: '160px' }} size='large' />
      ) : (
        <>
          <Collapse defaultActiveKey={['1', '2']} ghost>
            <Panel header='Selected Application' key='1'>
              <Row gutter={[16, 16]}>
                <Col key={`appSelected-${applicationId}`} className='applicationsPage-card' flex='200px'>
                  {(applications || [])
                    .filter((v: any) => v.value == applicationId)
                    .map((application) => applicationCard(application))}
                </Col>
                <Col key='appDetail' className='applicationsPage-details'>
                  {(applications || [])
                    .filter((v: any) => v.value == applicationId)
                    .map((application) => applicationDesc(application))}
                </Col>
              </Row>
            </Panel>

            <Panel header='Applications' style={{ minHeight: '180px' }} forceRender={true} key='2'>
              <ApplicationsTable
                apps={applications}
                loading={loading}
                applicationSelected={applicationId as string}
                onAppChange={(app) => changeApp(app)}
              ></ApplicationsTable>
            </Panel>
          </Collapse>
        </>
      )}
    </Page>
  );
};
