import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { AssignmentsMembersTable, Page, SearchUser } from '../components';
import { Col, Descriptions, Modal, notification, Row, Tabs, Tag, Typography } from 'antd';
import { UserValue } from '../components/SearchUser';
import useUsers, { IUser } from '../hooks/useUsers';
import useApplications from '../hooks/useApplications';
import usePermissions from 'hooks/usePermissions';
import usePrevious from '../hooks/usePrevious';
import useAssignments from '../hooks/useAssignments';
// import { WarningOutlined } from '@ant-design/icons';

const { Text } = Typography;
const { TabPane } = Tabs;

export default (props: { type?: string }) => {
  const { getSelectedApplication } = useApplications();
  const { applicationId = '', assignmentId = '' } = useParams<{ applicationId: string; assignmentId?: string }>();
  const {
    data: usersList = [],
    loading: usersLoading,
    removeAssignment,
    addUsersToAssignment,
    getAssignmentsUsersWithPagination,
    error,
  } = useUsers();
  const [userType, setUserType] = useState(props.type || 'users');
  const application = getSelectedApplication();
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);
  const [user, setUser] = useState<IUser | any>({});
  const [loading, setLoading] = useState(false);
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [deleteModalLoading, setDeleteModalLoading] = useState(false);
  const [pageSizeInner, setPageSizeInner] = useState<number>(0);
  const { pathname } = useLocation();
  const { checkPermissions } = usePermissions();
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const previousApp = usePrevious(applicationId);
  const { getAssignment, assignment } = useAssignments();

  const redirectToUserType = () => {
    const [_, page, assignment] = pathname.substring(1).split('/');
    navigate(`/${applicationId}/${page}/${assignment}/${userType || 'users'}`);
  };

  const loadUsers = () => {
    if (pageSizeInner === 0) return;
    setLoading(true);
    setUser([]);
    Promise.allSettled([
      getAssignmentsUsersWithPagination(applicationId, assignmentId, userType, pageSizeInner, 0).then(
        (userResponse: any) => {
          const { items = [], cursor = { self: 0, next: 0, total: 0 } } = userResponse.data;
          setUsers(items);
          setTotal(cursor.total);
        },
      ),
      getAssignment(applicationId, assignmentId),
    ]).then(() => setLoading(false));
  };

  useEffect(() => {
    previousApp && navigate(`/${applicationId}/assignments`);
  }, [applicationId]);

  useEffect(() => {
    if (pageSizeInner > 0) loadUsers();
  }, [pageSizeInner]);

  const onDelete = (user: IUser): void => {
    setVisibleDeleteModal(true);
    setUser(user);
  };

  const onPageChange = (pageNumber: number, pageSize: number) => {
    getAssignmentsUsersWithPagination(
      applicationId || '',
      assignmentId,
      userType,
      pageSize,
      (pageNumber - 1) * pageSize,
    );
  };

  const onPaginationLoad = (settings: { pageSizeInner: number }) => {
    setPageSizeInner(settings.pageSizeInner);
  };

  const onAssign = (users: UserValue[], setValue: (users: UserValue[]) => void) => {
    addUsersToAssignment(
      applicationId,
      users.map((user: UserValue) => user.value),
      assignmentId,
      userType,
    ).then((results: any) => {
      results.map((result: any) => {
        const getUserLabel = (id: string) => users.filter((user) => user.value === id)[0].label;
        const id = (result.value || result.reason).config?.url.split('/')[4];
        if (result.status === 'fulfilled') {
          notification.success({
            message: 'Success',
            description: `The user "${getUserLabel(id)}" was successfully assigned to "${assignmentId}"`,
          });
        } else {
          notification.error({
            message: 'Error',
            description:
              result?.data?.message ||
              `Error during assign the user "${getUserLabel(id)}" to assignment code "${assignmentId}"`,
          });
        }
      });
      setValue([]);
      loadUsers();
    });
  };

  const handleRemoveAssignment = async () => {
    setDeleteModalLoading(true);
    await removeAssignment(applicationId, user.id, assignmentId).then(() => {
      setVisibleDeleteModal(false);
      setDeleteModalLoading(false);
      loadUsers();
    });
  };

  const createModalError = (error: any) => {
    if (error?.data?.message) {
      setModalMessage(error?.data?.message || 'An error occurred while processing the request');
      setModalVisible(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    error && createModalError(error);
  }, [error]);

  const renderOptions = (user: any) => ({
    label:
      user?.label ||
      [
        user?.profile?.firstName,
        user?.profile?.lastName,
        `<${user?.profile?.email}>`,
        (user?.assignments || []).indexOf(assignmentId) >= 0 ? '(Added)' : '',
      ].join(' '),
    value: user?.id,
    disabled: (user?.assignments || []).indexOf(assignmentId) >= 0,
  });

  const renderFooter = () => {
    const { dynamic = false, lenient = false } = (assignment || { dynamic: false, lenient: false }) as any;

    const slot = {
      right: (userType === 'clients' || // enable for clients
        (userType === 'users' && (!dynamic || lenient))) &&
        checkPermissions(applicationId, 'assignments:create') && (
          <SearchUser applicationId={applicationId} onAssign={onAssign} userType={userType} mapOption={renderOptions} />
        ),
    };
    return (
      <Row>
        <Col span={24}>
          <Descriptions size='small' column={1}>
            <Descriptions.Item label='Application' labelStyle={{ fontWeight: 'bold' }}>
              {application?.name || ''}
            </Descriptions.Item>
            <Descriptions.Item label='Assignment code' labelStyle={{ fontWeight: 'bold' }}>
              {assignmentId || ''}
            </Descriptions.Item>
            {assignment?.dynamic && (
              <Descriptions.Item label='Rule' labelStyle={{ fontWeight: 'bold' }}>
                <Link to={`/${applicationId}/dynamic-assignments/${(assignment as any).id || ''}/edit`}>
                  {' '}
                  <Tag color={`green`} style={{ margin: '5px' }} key={`${(assignment as any)?.id}-tags}`}>
                    {(assignment as any)?.name || assignment.code || ''}
                  </Tag>
                </Link>
              </Descriptions.Item>
            )}
          </Descriptions>
        </Col>
        <Tabs
          defaultActiveKey='1'
          style={{ width: '100%' }}
          activeKey={userType}
          onChange={setUserType}
          tabBarExtraContent={slot}
        >
          <TabPane tab='Users' key='users' />
          <TabPane tab='Clients' key='clients' />
        </Tabs>
      </Row>
    );
  };

  useEffect(() => {
    if (userType) {
      if (userType && !pathname.toString().endsWith(`/${userType}`)) {
        redirectToUserType();
      }
      loadUsers();
    } else {
      redirectToUserType();
    }
  }, [userType, assignmentId]);

  useEffect(() => {
    if ((usersList || []).length === 0) {
      setLoading(true);
      loadUsers();
    } else {
      setUsers(usersList?.items);
    }
  }, [usersList]);

  return (
    <Page title={`Assignment: ${assignmentId}`} headerFooter={renderFooter()} backLevels={2}>
      <AssignmentsMembersTable
        users={users}
        total={total}
        userType={userType}
        onDelete={onDelete}
        hideAssignments
        loading={loading || usersLoading}
        onPageChange={onPageChange}
        onPaginationLoad={onPaginationLoad}
      />
      <Modal
        title='Alert'
        visible={modalVisible}
        onOk={() => setModalVisible(false)}
        cancelButtonProps={{ disabled: true }}
        onCancel={() => setModalVisible(false)}
      >
        <p>{modalMessage}</p>
      </Modal>
      <Modal
        title='Alert'
        visible={visibleDeleteModal}
        onOk={handleRemoveAssignment}
        okButtonProps={{ danger: true, disabled: deleteModalLoading }}
        okText={deleteModalLoading ? 'Removing' : 'Remove'}
        cancelButtonProps={{ disabled: deleteModalLoading }}
        confirmLoading={deleteModalLoading}
        onCancel={() => setVisibleDeleteModal(false)}
      >
        <div style={{ textAlign: 'center', padding: '10px' }}>
          <h3>Do you want to remove the {userType === 'users' ? 'user' : 'client'} from assignment?</h3>
        </div>
        <Descriptions size='small' column={1}>
          <Descriptions.Item
            label={(userType === 'users' ? 'User' : 'Client') || '???'}
            labelStyle={{ fontWeight: 'bold' }}
          >
            <Text code>{userType === 'users' ? user?.profile?.email : user?.label}</Text>
          </Descriptions.Item>
          <Descriptions.Item label='Assignment' labelStyle={{ fontWeight: 'bold' }}>
            <Text code>{assignmentId || ''}</Text>
          </Descriptions.Item>
        </Descriptions>
      </Modal>
    </Page>
  );
};
