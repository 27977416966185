import { Modal, Space, Table, Typography } from 'antd';
import Column from 'antd/es/table/Column';
import { Link, useParams } from 'react-router-dom';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { IRole } from '../hooks/useRoles';
import PaginationGeneric from './PaginationGeneric';
import usePermissions from 'hooks/usePermissions';

const { Text } = Typography;
export default (props: {
  roles: any;
  total: number;
  loading: boolean;
  onPageChange?: (val: number, pageSize: number) => void;
  onDelete: (role: IRole) => Promise<any>;
}) => {
  const { onPageChange } = props;
  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [deleteModalLoading, setDeleteModalLoading] = useState(false);
  const [deleteRole, setDeleteRole] = useState<IRole>({
    applicationCode: '',
    applicationId: '',
    applicationRoleCode: '',
    code: '',
    created: '',
    description: '',
    updated: '',
    name: '',
    id: '',
  });
  const { applicationId } = useParams();
  const { checkPermissions } = usePermissions();

  const handleOk = () => {
    setDeleteModalLoading(true);
    props.onDelete(deleteRole).then(() => {
      setDeleteModalLoading(false);
      setVisibleDeleteModal(false);
    });
  };

  const deleteRoleModal = (role: any) => {
    setDeleteRole(role);
    setVisibleDeleteModal(true);
  };

  const editCta = (role: any) => {
    return (
      checkPermissions(applicationId, 'roles:update') && (
        <Link to={`${role.id}/edit`}>
          <EditOutlined style={{ fontSize: '18px' }} title='edit' />
        </Link>
      )
    );
  };

  const deleteCta = (role: any) => {
    return (
      checkPermissions(applicationId, 'roles:delete') && (
        <DeleteOutlined
          style={{ fontSize: '18px', color: 'red' }}
          title='delete'
          onClick={() => deleteRoleModal(role)}
        />
      )
    );
  };

  const renderActions = (role: any) => (
    <Space size='middle'>
      {editCta(role) || deleteCta(role) ? (
        <>
          {editCta(role)}
          {deleteCta(role)}
        </>
      ) : (
        '-'
      )}
    </Space>
  );

  const pageChange = (val: number, pageSize: number) => {
    if (typeof onPageChange === 'function') onPageChange(val, pageSize);
  };

  return (
    <>
      <Table rowKey='id' dataSource={props.roles} pagination={false} loading={props.loading}>
        <Column title='Name' render={({ id, name }) => <Link to={id}>{name}</Link>} />
        <Column title='Actions' key='role-table-col-actions' align='center' render={renderActions} />
      </Table>

      <PaginationGeneric
        total={props.total}
        hideOnSinglePage={true}
        showSizeChanger={true}
        onPageChange={(page, pageSize) => {
          pageChange(page, pageSize);
        }}
      ></PaginationGeneric>

      <Modal
        title='Alert'
        visible={visibleDeleteModal}
        onOk={handleOk}
        centered
        okButtonProps={{ danger: true, disabled: deleteModalLoading }}
        okText={deleteModalLoading ? 'Removing' : 'Remove'}
        cancelButtonProps={{ disabled: deleteModalLoading }}
        confirmLoading={deleteModalLoading}
        onCancel={() => setVisibleDeleteModal(false)}
      >
        <p>
          Do you want to remove the role<Text code>{deleteRole.name || ''}</Text>?
        </p>
      </Modal>
    </>
  );
};
