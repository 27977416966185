import useResources, { IResource } from './useResources';
import useRoles, { IRole } from './useRoles';
import { useEffect, useState } from 'react';
import useAxios from './useAxios';
import { IAssignmentRuleItem } from './useAssignmentRules';

interface IAssignmentItem {
  code: string;
  applicationId: string;
  roleId: string;
  resourceId?: string;
  dynamic: boolean;
}

export default (): {
  error: any;
  loading: boolean;
  resources: IResource[];
  assignments: any;
  roles: IRole[];
  assignment: IAssignmentRuleItem | IAssignmentItem;
  getAll: (applicationId: string) => Promise<any>;
  getAssignment: (applicationId: string, code: string) => Promise<any>;
  getAllApplicationAssignments: (applicationId: string) => Promise<any>;
  loadingResource: boolean;
  loadingRoles: boolean;
  loadingAssignments: boolean;
  assignmentMap: Map<string, any>;
  roleMap: Map<string, IRole>;
  resourceMap: Map<string, IResource>;
} => {
  const { data: payload, loading: loadingAssignments, requestAsync } = useAxios();
  const { getAll: getAllResources, error: errorResource, loading: loadingResource } = useResources();
  const { error: errorRoles, loading: loadingRoles, getAllWithLimit: getRoles } = useRoles();
  const [resources, setResources] = useState<IResource[]>([]);
  const [assignments, setAssignments] = useState<any[]>([]);
  const [assignment, setAssignment] = useState<IAssignmentItem | IAssignmentRuleItem | any>();
  const [roles, setRoles] = useState<IRole[]>([]);
  const [assignmentMap] = useState<Map<string, any>>(new Map());
  const roleMap: Map<string, IRole> = new Map();
  const resourceMap: Map<string, IResource> = new Map();

  useEffect(() => {
    roles.reduce((acc, role: IRole) => acc.set(role.id, role) && acc, roleMap);
  }, [roles]);

  useEffect(() => {
    resources.reduce((acc, resource: IResource) => acc.set(resource.id, resource) && acc, resourceMap);
  }, [resources]);

  useEffect(() => {
    setAssignments(payload?.items || []);
  }, [payload]);

  const getAll = async (applicationCode: string): Promise<any> => {
    return Promise.allSettled([getAllResources(applicationCode), getRoles(applicationCode, 1000, 0)]).then((result) => {
      const [resourcesResult, rolesResult] = result;
      const resources = resourcesResult?.status === 'fulfilled' ? resourcesResult.value : [];
      const roles = rolesResult?.status === 'fulfilled' ? rolesResult.value : [];
      setResources(resources);
      setRoles(roles);

      const assignments: string[] = [];

      roles.forEach((role: IRole) => {
        const assignmentCode = `${applicationCode}.${role.code}`;
        assignmentMap.set(assignmentCode, {
          dynamic: false,
          code: assignmentCode,
          applicationId: role.applicationId,
          roleId: role.id,
          role,
        });
        assignments.push(assignmentCode);
      });

      resources.forEach((resource: IResource) => {
        const { code, allowedRoleIds, parentResource } = resource;
        (allowedRoleIds || [])
          .filter((id: string) => roleMap.has(id))
          .forEach((id: string) => {
            const assignmentCode = `${applicationCode}.${
              parentResource?.code ? `${parentResource.code}.${code}` : `${code}`
            }.${roleMap.get(id)}`;
            assignmentMap.set(assignmentCode, {
              dynamic: false,
              code: assignmentCode,
              applicationId: resource.applicationId,
              roleId: id,
              resourceId: resource.id,
              role: roleMap.get(id),
              resource,
            });
            assignments.push(assignmentCode);
          });
      });

      setAssignments(assignments);

      return assignments;
    });
  };

  const getAllApplicationAssignments = async (applicationId: string): Promise<void> => {
    await getAll(applicationId);
    return await requestAsync(`applications/${applicationId}/assignments`, 'get', {}, (data: any) => {
      const { items = [] } = data;
      for (const item of items) {
        assignmentMap.set(item.code, { ...assignmentMap.get(item.id), ...item });
      }
      return data;
    });
  };

  const getAssignment = async (applicationId: string, code: string): Promise<any> => {
    return await requestAsync(`applications/${applicationId}/assignments/${code}`, 'get', {}, (data: any) => {
      setAssignment(data);
    });
  };

  return {
    assignment,
    assignments,
    resources,
    roles,
    error: errorResource || errorRoles,
    loading: loadingResource || loadingRoles || loadingAssignments,
    getAll,
    getAllApplicationAssignments,
    loadingResource,
    loadingRoles,
    loadingAssignments,
    assignmentMap,
    roleMap,
    resourceMap,
    getAssignment,
  };
};
