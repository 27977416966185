import { Page, ResourceSelect, TagList, JSONEditorReact, OktaGroupSelect } from '../components';
import { useEffect, useState } from 'react';
import useApplications, { IApplication } from 'hooks/useApplications';
import { BorderOutlined, DeleteOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Divider, Form, Input, List, Modal, Row, Spin, Typography, notification, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import useResources, { IResource } from '../hooks/useResources';
import useRoles, { IRole } from 'hooks/useRoles';
import RolesSelect from 'components/RolesSelect';
import useBreakpoint from 'antd/es/grid/hooks/useBreakpoint';
import generalContent from '../conf/generalContent.json';
import usePermissions from 'hooks/usePermissions';

interface IModal {
  text: string;
  targetFunction: (values: any) => void;
  values: object;
}

const { Text } = Typography;

export default () => {
  const { Title } = Typography;
  const { applicationId = '', applicationCode = '' } = useParams();
  const screens = useBreakpoint();

  const [applications, setApplications] = useState<any>([]);
  const [applicationSelected, setapplicationSelected] = useState<IApplication | undefined>();
  const [tempApp, setTempApp] = useState({}); // When editing, keep the old form values
  const [fromAppId, setFromAppId] = useState(false);
  const [visibleSaveModal, setVisibleSaveModal] = useState(false);
  const [saveModalLoading, setSaveModalLoading] = useState(false);
  const [modalValues, setModalValues] = useState<IModal>();
  const [settingsEditorFullScreen, setSettingsEditorFullScreen] = useState(false);

  const [defaultRoleAssignmentsInner, setDefaultRoleAssignmentsInner] = useState<any[]>([]);
  const [selfServiceAssignments, setSelfServiceAssignments] = useState<string[]>([]);
  const [selfServiceAssignmentsTags, setSelfServiceAssignmentsTags] = useState<string[]>([]);

  const { getSelectedApplication } = useApplications();
  const { checkPermissions } = usePermissions();

  const {
    code: codeTooltip,
    description: descriptionTooltip,
    name: nameTooltip,
    oktaClientId: oktaClientIdTolltip,
    oktaGroup: oktaGroupNameTooltip,
    selfAssignments: selfAssignmentsTooltip,
  } = generalContent.pages.applications;

  const { resource: resourceTooltip, role: roleTooltop } = generalContent.general.edit;

  const {
    data,
    getAll,
    loading,
    error,
    updateNameDescription,
    updateSelfServiceAssignments,
    updateSettings,
    updateAppLocalStorage,
  } = useApplications();
  const { pathname } = useLocation();

  const [formNameDescription] = Form.useForm();
  const [formSelfServiceAssignment] = Form.useForm();
  const navigate = useNavigate();

  const { data: dataResources, getAllWithLimit: getResources, loading: loadingResources } = useResources();
  const [resources, setResources] = useState<IResource[]>([]);

  const { data: dataRoles, getAllWithLimit: getRoles, loading: loadingRoles } = useRoles();
  const [roles, setRoles] = useState<IRole[]>([]);

  // eslint-disable-next-line no-return-assign, no-param-reassign
  // prettier-ignore
  const [settingsJson, setSettingsJson] = useState({
        "json":{},
        "text": undefined
     });
  const [jsonError, setJsonError] = useState();

  const edit = pathname.endsWith('/edit');

  const goFullScreenIcon = {
    prefix: 'fas',
    iconName: 'jsoneditor-expand',
    icon: [
      512,
      512,
      [],
      '',
      `M 454.2 166 a 30 30 90 0 0 30 -30 V 30 a 30 30 90 0 0 -30 -30 h -106 a 30 30 90 0 0 0 60 h 33.6 l -139.6 139.8 L 102.4 60 H 136 a 30 30 90 0 0 0 -60 H 30 A 30 30 90 0 0 0 30 v 106 a 30 30 90 0 0 60 0 V 102.4 l 139.8 139.8 L 60 381.8 v -33.6 a 30 30 90 1 0 -60 0 v 106 a 30 30 90 0 0 30 30 h 106 a 30 30 90 0 0 0 -60 H 102.4 l 139.8 -139.6 l 139.6 139.6 h -33.6 a 30 30 90 0 0 0 60 h 106 a 30 30 90 0 0 30 -30 v -106 a 30 30 90 0 0 -60 0 v 33.6 l -139.6 -139.6 L 424 102.4 V 136 a 30 30 90 0 0 30 30 z`,
    ],
  };
  const exitFullScreenIcon = {
    prefix: 'fas',
    iconName: 'jsoneditor-regular',
    icon: [
      512,
      512,
      [],
      '',
      `M456 224H312c-13.3 0-24-10.7-24-24V56c0-9.7 5.8-18.5 14.8-22.2s19.3-1.7 26.2 5.2l40 40L442.3 5.7C446 2 450.9 0 456 0s10 2 13.7 5.7l36.7 36.7C510 46 512 50.9 512 56s-2 10-5.7 13.7L433 143l40 40c6.9 6.9 8.9 17.2 5.2 26.2s-12.5 14.8-22.2 14.8zm0 64c9.7 0 18.5 5.8 22.2 14.8s1.7 19.3-5.2 26.2l-40 40 73.4 73.4c3.6 3.6 5.7 8.5 5.7 13.7s-2 10-5.7 13.7l-36.7 36.7C466 510 461.1 512 456 512s-10-2-13.7-5.7L369 433l-40 40c-6.9 6.9-17.2 8.9-26.2 5.2s-14.8-12.5-14.8-22.2V312c0-13.3 10.7-24 24-24H456zm-256 0c13.3 0 24 10.7 24 24V456c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-40-40L69.7 506.3C66 510 61.1 512 56 512s-10-2-13.7-5.7L5.7 469.7C2 466 0 461.1 0 456s2-10 5.7-13.7L79 369 39 329c-6.9-6.9-8.9-17.2-5.2-26.2s12.5-14.8 22.2-14.8H200zM56 224c-9.7 0-18.5-5.8-22.2-14.8s-1.7-19.3 5.2-26.2l40-40L5.7 69.7C2 66 0 61.1 0 56s2-10 5.7-13.7L42.3 5.7C46 2 50.9 0 56 0s10 2 13.7 5.7L143 79l40-40c6.9-6.9 17.2-8.9 26.2-5.2s14.8 12.5 14.8 22.2V200c0 13.3-10.7 24-24 24H56z`,
    ],
  };

  let modalValuesRequest: any = {};

  if (fromAppId) {
    setFromAppId(false);
  }

  useEffect(() => {
    return () => {
      if (edit) {
        formNameDescription.setFieldsValue(tempApp);
      }
    };
  }, [navigate]);

  useEffect(() => {
    data && data?.items
      ? setApplications(data.items.map((value: any) => ({ label: value.name, value: value.code, ...value })))
      : getAll();
  }, [data]);

  useEffect(() => {
    const appTarget = applications?.filter((app: any) => app.id?.toString() == applicationCode?.toString());
    if (appTarget?.length) {
      const app = appTarget[0];
      setapplicationSelected(app);
      localStorage.setItem('application', JSON.stringify(app));
    }
  }, [applications, applicationCode]);

  useEffect(() => {
    const appTarget = applications?.filter((app: any) => app.code?.toString() == applicationId?.toString());
    if (appTarget?.length) {
      const app = appTarget[0];
      setapplicationSelected(app);
      localStorage.setItem('application', JSON.stringify(app));
    }

    // Resources and Roles - Default Assignments
    setResources([]);
    setRoles([]);
    setSelfServiceAssignmentsTags([]);

    getResources(applicationId || '', 200);
    getRoles(applicationId || '', 200);
  }, [applicationId]);

  // Resources
  useEffect(() => {
    if (dataResources?.items?.length) setResources(dataResources.items);
  }, [dataResources]);

  // Roles
  useEffect(() => {
    if (dataRoles?.items?.length) setRoles(dataRoles.items);
  }, [dataRoles]);

  // Selected APP
  useEffect(() => {
    if (applicationSelected) {
      setDefaultRoleAssignmentsInner([]);
      setSelfServiceAssignments([]);
      setSettingsJson({ json: {}, text: undefined });
      formNameDescription.setFieldsValue(applicationSelected);
      formSelfServiceAssignment.setFieldsValue(applicationSelected);
      setTempApp(applicationSelected);

      if (applicationSelected?.defaultRoleAssignments) {
        setDefaultRoleAssignmentsInner(applicationSelected.defaultRoleAssignments);
      }

      if (applicationSelected?.selfServiceAssignments?.length) {
        setSelfServiceAssignments(applicationSelected.selfServiceAssignments);
        setSelfServiceAssignmentsTags(applicationSelected.selfServiceAssignments);
      } else {
        setSelfServiceAssignments(selfServiceAssignmentsTags);
      }

      if (applicationSelected?.settings) {
        const targetObj = applicationSelected?.settings;
        setSettingsJson({ json: targetObj, text: undefined } || { json: undefined });
      }
    }
    setVisibleSaveModal(false);
    setSaveModalLoading(false);
  }, [applicationSelected]);

  useEffect(() => {
    if (!loadingRoles && !loadingResources) {
      const getRoleId = (roleId: string) => {
        return roles?.find((obj: { id: string }) => obj?.id === roleId);
      };
      const getResourceId = (resourceId: string) => {
        return resources?.find((obj: { id: string }) => obj?.id === resourceId);
      };

      // Mapping all Default Assignments Tags
      const tempTags: string[] = [];
      if (defaultRoleAssignmentsInner.length) {
        defaultRoleAssignmentsInner?.map((obj) => {
          const roleId: string = obj?.roleId || '';
          const roleCode: { code: string | undefined } = getRoleId(roleId) || { code: undefined };
          const resourceId = obj?.resourceId || '';
          const resourceCode: { code: string | undefined; id?: string } = getResourceId(resourceId) || {
            code: undefined,
          };

          const finalCode = resourceCode?.code
            ? `${applicationId}.${resourceCode?.code}.${roleCode?.code}`
            : `${applicationId}.${roleCode?.code}`;
          tempTags.push(finalCode);
        });
      }
    }
  }, [defaultRoleAssignmentsInner, roles, resources, navigate]);

  const notificationMessage = (description: string) => {
    notification.success({
      message: 'Success',
      description,
    });
  };
  const notificationErrorMessage = (description: string) => {
    notification.error({
      message: 'Error!',
      description,
    });
  };

  // All API errors message
  useEffect(() => {
    if (error?.data?.error) {
      const errorMessage = `${error.data.error?.split('-')[0]?.trim()}.`;
      notificationErrorMessage(errorMessage);
    }
  }, [error]);

  useEffect(() => {
    setTempApp(applicationSelected || []);
  }, [!loading]);

  const closeModal = function () {
    setVisibleSaveModal(false);
  };

  // -- Name/Description Form
  const saveAppChangesRequest = function () {
    updateNameDescription(applicationId, modalValuesRequest?.name, modalValuesRequest?.description || '', () => {
      notificationMessage('The app has been updated successfully!');
      if (applicationSelected) {
        applicationSelected.name = modalValuesRequest.name || '';
        applicationSelected.description = modalValuesRequest.description || '';
      }
    });

    setVisibleSaveModal(false);
  };

  const saveAppChanges = function (values: any) {
    setModalValues({
      text: `Do you want to <b>save</b> the <span class="ant-typography"><code> ${applicationId}</code></span> application?`,
      targetFunction: saveAppChangesRequest,
      values,
    });
    modalValuesRequest = values;
    setVisibleSaveModal(true);
  };

  // -- add SELF ASSIGNMENT Form
  const addSelfAssignmentsRequest = function () {
    const resourceSelfAss: string = modalValuesRequest.resultSelfAssignments;
    const finalObj = [resourceSelfAss, ...selfServiceAssignments];

    updateSelfServiceAssignments(applicationId, finalObj, () => {
      notificationMessage('The self assignments has been updated successfully!');
      setSelfServiceAssignments(finalObj);
      setSelfServiceAssignmentsTags(finalObj);
    });

    setVisibleSaveModal(false);
  };

  const addSelfAssignments = function (values: any) {
    const addValue = values.resultSelfAssignments;
    const hasValue = values.selfServiceAssignments?.find((el: string) => {
      return el === addValue;
    });

    if (hasValue) {
      setModalValues({
        text: `The default assignment
          <span class="ant-typography">
            <code>${addValue}</code>
          </span> <b>already exists</b> in the <span class="ant-typography"><code>${applicationId}</code></span> application.`,
        targetFunction: closeModal,
        values,
      });
      setVisibleSaveModal(true);
    } else {
      setModalValues({
        text: `Do you want to <b>add</b> the self assignment
        <span class="ant-typography">
          <code>${values.resultSelfAssignments}</code>
        </span> to the <span class="ant-typography"><code>${applicationId}</code></span> application?`,
        targetFunction: addSelfAssignmentsRequest,
        values,
      });

      modalValuesRequest = values;

      setVisibleSaveModal(true);
    }
  };

  const deleteSelfAssignmentRequest = function () {
    const newSelfAssignments = selfServiceAssignments?.filter((el) => el !== modalValuesRequest);

    updateSelfServiceAssignments(applicationId, newSelfAssignments, () => {
      notificationMessage('The self assignments has been deleted successfully!');
      setSelfServiceAssignments(newSelfAssignments || []);
      setSelfServiceAssignmentsTags(newSelfAssignments || []);
    });

    setVisibleSaveModal(false);
  };

  const deleteSelfAssignmentModal = function (selfAssignmentTag: string) {
    modalValuesRequest = selfAssignmentTag;

    setModalValues({
      text: `Do you want to <b>delete</b> the self assignment
        <span class="ant-typography">
          <code>${selfAssignmentTag}</code>
        </span> from the <span class="ant-typography"><code>${applicationId}</code></span> application?`,
      targetFunction: deleteSelfAssignmentRequest,
      values: { selfAssignmentTag },
    });

    setVisibleSaveModal(true);
  };

  const addSelfAssignmentsValues = function (_prev: any, values: any) {
    const { resourceSelfAssignments, roleSelfAssignments } = values;
    if (roleSelfAssignments) {
      formSelfServiceAssignment.setFieldValue(
        'resultSelfAssignments',
        resourceSelfAssignments
          ? `${applicationId}.${resourceSelfAssignments}.${roleSelfAssignments}`
          : `${applicationId}.${roleSelfAssignments}`,
      );
    }
  };

  // Settings
  const updateSettingsRequest = function () {
    const settingVal = settingsJson?.json || settingsJson?.text;
    const finalObj = typeof settingVal === 'string' ? JSON.parse(settingVal) : settingVal;
    const settingsObj = { ...finalObj };

    updateSettings(applicationId, settingsObj, () => {
      notificationMessage('The settings has been updated successfully!');
      setSettingsJson({ json: finalObj, text: undefined });
    });

    updateAppLocalStorage('settings', finalObj);

    const localApp = getSelectedApplication();
    const appPop = applications?.filter((app: IApplication) => app.id !== localApp.id);
    const updatedApps = [...appPop, localApp];
    setApplications(updatedApps);

    setVisibleSaveModal(false);
  };

  const updateSettingsModal = function () {
    const settingVal = settingsJson?.json || settingsJson?.text;
    if (Object.getOwnPropertyNames(settingVal).length !== 0) {
      const parseSettingsVal = JSON.parse(JSON.stringify(settingVal));

      setModalValues({
        text: `Do you want to <b>update</b> the <b>settings</b>
          <span class="ant-typography">
          </span> from the <span class="ant-typography"><code>${applicationId}</code></span> application?`,
        targetFunction: updateSettingsRequest,
        values: { parseSettingsVal },
      });
      setVisibleSaveModal(true);
    }
  };

  function toggleFullScreen() {
    setSettingsEditorFullScreen(!settingsEditorFullScreen);
    settingsEditorFullScreen
      ? document.body.classList.remove('overflowHidden')
      : document.body.classList.add('overflowHidden');
  }

  function handleRenderMenu(items: any) {
    const separator = {
      separator: true,
    };

    const customCopyButton = {
      onClick: toggleFullScreen,
      icon: !settingsEditorFullScreen ? goFullScreenIcon : exitFullScreenIcon,
      title: !settingsEditorFullScreen ? 'Go full screen' : 'Exit full screen',
      className: 'custom-fullscreen-button',
    };

    const itemsWithoutSpace = items.slice(0, items.length - 2);

    return itemsWithoutSpace.concat([separator, customCopyButton]);
  }

  const linkDocs = (
    <a href={generalContent.general.linkDocumentation} target='_blank' rel='noreferrer'>
      Learn more.
    </a>
  );

  const readOnly = !edit;
  const disabled = readOnly || loading;
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  const colLeftSize = {
    xs: 24,
    md: edit ? 24 : 16,
    lg: edit ? 16 : 11,
    xl: edit ? 16 : 8,
    xxl: edit ? 12 : 6,
  };
  const colRightSize = {
    xs: 24,
    md: edit ? 24 : 12,
    lg: edit ? 8 : 13,
    xl: edit ? 8 : 10,
    xxl: 12,
  };
  const fieldClassName = readOnly ? 'show-only-field' : '';

  const itemsStyles = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: edit ? 24 : 8 },
      md: { span: edit ? 4 : 8 },
      lg: { span: edit ? 4 : 12 },
      xl: { span: edit ? 3 : 12 },
    },
    wrapperCol: { xs: { span: 24 }, sm: { span: edit ? 24 : 6 }, lg: { span: edit ? 24 : 12 } },
    className: 'txt-bold',
  };

  const itemsStylesRight = {
    labelCol: { xs: { span: 24 }, sm: { span: edit ? 6 : 8 }, md: { span: edit ? 5 : 8 }, lg: { span: 12 } },
    wrapperCol: { xs: { span: 24 }, sm: { span: 6 }, lg: { span: 12 } },
    className: 'txt-bold',
  };

  const assignmentStyles = {
    xs: 24,
    md: edit ? 24 : 12,
    lg: 12,
    xl: edit ? 12 : 10,
    xxl: 12,
  };

  return (
    <Page
      title={edit ? 'Edit Application' : 'Application'}
      titleIcon={<BorderOutlined />}
      extra={
        !edit &&
        checkPermissions(applicationId, 'applications:update') && (
          <Button key='edit-app' type='primary' onClick={() => setFromAppId(true)}>
            <Link to='edit'>Edit Application</Link>
          </Button>
        )
      }
      className='applicationPage'
    >
      {loading && !edit ? (
        <Spin style={{ minHeight: '400px', position: 'relative', left: '50%', top: '160px' }} size='large' />
      ) : (
        <>
          <Row gutter={[24, 0]}>
            <Col {...colLeftSize}>
              {/* Name/desc FORM */}
              <Form
                {...formItemLayout}
                labelCol={{ span: 6 }}
                onFinish={saveAppChanges}
                wrapperCol={{ span: 24 }}
                form={formNameDescription}
                initialValues={{ ...applicationSelected }}
                key={'nameDescription'}
                name={'nameDescription'}
              >
                <Form.Item
                  {...itemsStyles}
                  name='name'
                  label='Name'
                  rules={[
                    {
                      required: !readOnly,
                      pattern: new RegExp(/^(?!\s)(?![\w_ -]*\s\s)([a-zA-Z0-9 _-]{2,50})$/g),
                      message:
                        'Name required. It must be 2-50 characters long. No double or finishing with whitespace. No special characters but "-" or "_".',
                    },
                  ]}
                  validateTrigger={['onChange', 'onBlur']}
                  tooltip={{
                    title: (
                      <>
                        {nameTooltip} {linkDocs}
                      </>
                    ),
                    overlayInnerStyle: { textAlign: 'center' },
                  }}
                  shouldUpdate
                >
                  <Input disabled={disabled} className={fieldClassName} />
                </Form.Item>

                <Form.Item
                  {...itemsStyles}
                  name='description'
                  label='Description'
                  tooltip={{
                    title: (
                      <>
                        {descriptionTooltip} {linkDocs}
                      </>
                    ),
                    overlayInnerStyle: { textAlign: 'center' },
                  }}
                  shouldUpdate
                >
                  <TextArea disabled={disabled} className={fieldClassName} autoSize={{ minRows: 1, maxRows: 4 }} />
                </Form.Item>

                <Row hidden={!edit}>
                  <Col span={24} style={{ textAlign: 'right' }}>
                    <Form.Item wrapperCol={{ sm: 24 }} shouldUpdate>
                      <Button type='primary' loading={saveModalLoading} disabled={saveModalLoading} htmlType='submit'>
                        {'Save'}
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>

              <Divider
                type='horizontal'
                style={{
                  display: `${edit ? 'block' : 'none'}`,
                  margin: '25px 0',
                  borderColor: 'grey',
                  opacity: '0.4',
                }}
              />

              {/* Self Service Assigment FORM */}
              <Form
                {...formItemLayout}
                labelCol={{ span: 6 }}
                onValuesChange={addSelfAssignmentsValues}
                onFinish={addSelfAssignments}
                wrapperCol={{ span: 24 }}
                form={formSelfServiceAssignment}
                initialValues={{ ...applicationSelected }}
                key={'addSelfAssignments'}
                name={'addSelfAssignments'}
              >
                {/* Self Service Assigment */}
                <Form.Item
                  {...itemsStyles}
                  label='Self Service Assignments'
                  name='selfServiceAssignments'
                  hidden={edit}
                  tooltip={{
                    title: (
                      <>
                        {selfAssignmentsTooltip} {linkDocs}
                      </>
                    ),
                    overlayInnerStyle: { textAlign: 'center' },
                  }}
                  shouldUpdate
                >
                  {selfServiceAssignmentsTags.length ? (
                    <TagList tags={selfServiceAssignmentsTags} styleProp={{ fontWeight: 'normal' }} />
                  ) : (
                    <span className='txt-normal'> -</span>
                  )}
                </Form.Item>
                {/* EDIT Self Assignments */}
                <Row gutter={[24, 0]}>
                  <Col hidden={!edit} {...assignmentStyles}>
                    <Title level={5}>Add Self Assignments:</Title>
                    <Form.Item
                      label='Resource'
                      name='resourceSelfAssignments'
                      tooltip={{
                        title: (
                          <>
                            {resourceTooltip} {linkDocs}
                          </>
                        ),
                        overlayInnerStyle: { textAlign: 'center' },
                      }}
                      shouldUpdate
                    >
                      <ResourceSelect loading={loadingResources} applicationId={applicationId} valueIndex={'code'} />
                    </Form.Item>
                    <Form.Item
                      name='roleSelfAssignments'
                      label='Roles'
                      rules={[{ required: true, message: 'Role is required!' }]}
                      tooltip={{
                        title: (
                          <>
                            {roleTooltop} {linkDocs}
                          </>
                        ),
                        overlayInnerStyle: { textAlign: 'center' },
                      }}
                      shouldUpdate
                    >
                      <RolesSelect loading={loadingRoles} valueIndex={'code'} applicationId={applicationId} />
                    </Form.Item>
                    <Form.Item shouldUpdate style={{ float: 'right' }}>
                      <Button
                        type='primary'
                        htmlType='submit'
                        loading={loadingResources}
                        disabled={loadingResources}
                        hidden={!edit}
                      >
                        Add Self Assignments
                      </Button>
                    </Form.Item>
                    <Form.Item name='resultSelfAssignments'>
                      <Input hidden />
                    </Form.Item>
                  </Col>
                  <Col hidden={!edit} {...assignmentStyles}>
                    <Title level={5}>
                      Self Assignments{' '}
                      <Tooltip
                        title={
                          <>
                            {selfAssignmentsTooltip} {linkDocs}
                          </>
                        }
                        overlayInnerStyle={{ textAlign: 'center' }}
                      >
                        <QuestionCircleOutlined
                          style={{ fontSize: '14px', color: 'rgba(0,0,0,.45)', cursor: 'help', marginRight: '2px' }}
                        />
                      </Tooltip>
                      :
                    </Title>
                    <List bordered style={{ width: '100%' }}>
                      {(selfServiceAssignmentsTags || []).length ? (
                        selfServiceAssignmentsTags.map((selfAssignment: any, index: number) => (
                          <List.Item
                            key={`${selfAssignment}-${index}`}
                            style={{ wordBreak: 'break-all' }}
                            extra={
                              edit && (
                                <DeleteOutlined
                                  onClick={() => deleteSelfAssignmentModal(selfAssignment)}
                                  style={{ paddingRight: '20px' }}
                                />
                              )
                            }
                          >
                            {selfAssignment}
                          </List.Item>
                        ))
                      ) : (
                        <List.Item>No Assignment</List.Item>
                      )}
                    </List>
                  </Col>
                </Row>
              </Form>

              {/* JSON Edit */}
              <Row hidden={!edit} className='settingsEdit'>
                <Divider
                  type='horizontal'
                  style={{
                    display: `${edit ? 'block' : 'none'}`,
                    margin: '25px 0',
                    borderColor: 'grey',
                    opacity: '0.4',
                  }}
                />
                <h2 className='full-width'>Settings - raw JSON</h2>
                <p>
                  Edit the settings with the editor below. <b>No need to wrap it with the key &quot;settings&quot;.</b>
                </p>
                <div className={`settingsEdit-jsonEditor ${settingsEditorFullScreen ? '-fullscreen' : ''}`}>
                  <JSONEditorReact
                    content={settingsJson}
                    readOnly={!edit}
                    onRenderMenu={handleRenderMenu}
                    onChange={(updatedContent: any, _previousContent: any, { contentErrors }: any) => {
                      setJsonError(contentErrors);
                      setSettingsJson(updatedContent);
                    }}
                    onError={(e: any) => console.log('error-jsonEditor==>', e)}
                    ValidationError={(e: any) => setJsonError(e)}
                  />
                </div>
                <Form
                  labelCol={{ span: 6 }}
                  onFinish={updateSettingsModal}
                  wrapperCol={{ span: 24 }}
                  key={'jsonEditor'}
                  name={'jsonEditor'}
                  className='settingsEdit-form'
                >
                  <Button type='primary' disabled={jsonError} htmlType='submit' className='settingsEdit-cta'>
                    {'Save'}
                  </Button>
                </Form>
              </Row>
            </Col>

            <Col {...colRightSize}>
              <Divider
                type='horizontal'
                style={{
                  display: `${edit && !screens.lg ? 'block' : 'none'}`,
                  margin: '25px 0',
                  borderColor: 'grey',
                  opacity: '0.4',
                }}
              />
              <Form key={'noEdit'} name={'noEdit'}>
                <Form.Item
                  {...itemsStylesRight}
                  label='Code'
                  name='code'
                  tooltip={{
                    title: (
                      <>
                        {codeTooltip} {linkDocs}
                      </>
                    ),
                    overlayInnerStyle: { textAlign: 'center' },
                  }}
                  shouldUpdate
                >
                  <span className='txt-normal'>{applicationSelected?.code ?? '-'}</span>
                </Form.Item>

                <Form.Item
                  {...itemsStylesRight}
                  label='Okta Group'
                  name='oktaGroup'
                  tooltip={{
                    title: (
                      <>
                        {oktaGroupNameTooltip} {linkDocs}
                      </>
                    ),
                    overlayInnerStyle: { textAlign: 'center' },
                  }}
                  shouldUpdate
                >
                  <span className='txt-normal'>
                    <OktaGroupSelect
                      readOnly={true}
                      applicationId={applicationId}
                      value={applicationSelected?.oktaGroupId}
                      className={'show-only-field'}
                    />
                  </span>
                  <Text style={{ marginLeft: '-0.5em' }} code>
                    ID: {applicationSelected?.oktaGroupId}
                  </Text>
                </Form.Item>
                <Form.Item
                  {...itemsStylesRight}
                  label='Okta Client ID'
                  name='oktaClientId'
                  tooltip={{
                    title: (
                      <>
                        {oktaClientIdTolltip} {linkDocs}
                      </>
                    ),
                    overlayInnerStyle: { textAlign: 'center' },
                  }}
                  shouldUpdate
                >
                  <span className='txt-normal'>
                    {applicationSelected?.oktaClientId ?? applicationSelected?.settings?.oktaId ?? '-'}
                  </span>
                </Form.Item>
              </Form>
            </Col>
          </Row>

          <Modal
            title='Alert'
            visible={visibleSaveModal}
            onOk={modalValues?.targetFunction}
            okButtonProps={{ disabled: saveModalLoading }}
            okText={saveModalLoading ? 'Working' : 'Ok'}
            cancelButtonProps={{ disabled: saveModalLoading }}
            confirmLoading={saveModalLoading}
            onCancel={() => setVisibleSaveModal(false)}
          >
            <p dangerouslySetInnerHTML={{ __html: modalValues?.text || '' }}></p>
          </Modal>
        </>
      )}
    </Page>
  );
};
