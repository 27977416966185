import { Table, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import PaginationGeneric from './PaginationGeneric';
import useLocalStorage from 'hooks/useLocalStorage';
import { LockOutlined } from '@ant-design/icons';

const { Column } = Table;

export default (props: {
  assignments: any;
  total: number;
  loading: boolean;
  applicationId: string;
  onPageChange?: (val: number, pageSize: number) => void;
}) => {
  const { assignments, total, loading, applicationId } = props;
  const [lsPageSize] = useLocalStorage<number>('am-page-size', 10);
  const [assignmentsArr, setAssignmentsArr] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSizeInner, setPageSizeInner] = useState(lsPageSize);

  useEffect(() => {
    const assignmentsArr = assignments
      .filter((value: any) => !!value.code) // Remove assignments with undefined code
      .filter(
        (value: any, index: number, self: any) =>
          index === self.findIndex((indexValue: any) => indexValue.code === value.code),
      ) // remove duplications
      .filter((assignment: any) => (assignment.code.split('.') || []).indexOf('') < 0);
    setAssignmentsArr(assignmentsArr);
  }, [assignments]);

  const pageChange = (currentPage: number, pageSize: number) => {
    setCurrentPage(currentPage);
    setPageSizeInner(pageSize);
  };

  const isItLenient = (assignment: any): boolean => {
    return assignment?.dynamic === true && assignment?.lenient === false;
  };

  // Amount of data to pass to Table
  const calPage = (currentPage - 1) * pageSizeInner;
  let assignmentsToShow = assignmentsArr?.slice(calPage, calPage + pageSizeInner);
  useEffect(() => {
    if (assignmentsArr && assignmentsArr.length > pageSizeInner) {
      assignmentsToShow = assignmentsArr?.slice(calPage, calPage + pageSizeInner);
      if (!assignmentsToShow?.length) {
        pageChange(1, pageSizeInner);
      }
    } else {
      assignmentsToShow = assignmentsArr;
      if (assignmentsToShow?.length) setCurrentPage(1);
    }
  }, [assignmentsToShow, total]);

  return (
    <>
      <Table rowKey='code' dataSource={assignmentsToShow} pagination={false} loading={loading}>
        <Column
          title='Name'
          key='name'
          render={(assignment: any) => {
            return (
              <Link to={assignment.code}>
                {assignment.code} {isItLenient(assignment) && <LockOutlined />}
              </Link>
            );
          }}
        />
        <Column
          title='Managed by'
          key='rules'
          align={'center'}
          render={(assignment: any) =>
            assignment?.dynamic === true ? (
              <label>
                By Rule
                <Link to={`/${applicationId}/dynamic-assignments/${assignment.id}`}>
                  <Tag color={`green`} style={{ margin: '5px' }} key={`${assignment.id}-tags}`}>
                    {assignment.name || assignment.code || ''}
                  </Tag>
                </Link>
              </label>
            ) : (
              '-'
            )
          }
        />
        <Column
          title='Users'
          key='users'
          align='center'
          render={(assignment: any) => <Link to={`${assignment.code}/users`}>{assignment.users}</Link>}
        />
        <Column
          title='Clients'
          key='clients'
          align='center'
          render={(assignment: any) => <Link to={`${assignment.code}/clients`}>{assignment.clients}</Link>}
        />
      </Table>

      <PaginationGeneric
        loading={loading}
        total={total}
        hideOnSinglePage={true}
        showSizeChanger={true}
        onPageChange={(page, pageSize) => {
          pageChange(page, pageSize);
        }}
      ></PaginationGeneric>
    </>
  );
};
